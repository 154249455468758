import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import AnimateHeight from 'react-animate-height';

class PropertyIns extends React.Component {
    state = {
        height: {
            0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 
        },
    }

    toggle = (index) => {
        const { height } = this.state;
        height[index] = height[index] === 0 ? 'auto' : 0
        this.setState({
            height: height,
        });
    };

    render() {

        return (
            <div className='tab-info' id='propertyInsInfo'>

                <h4><span className='icon'><img src='/icons/home.svg' alt='' /></span>За дома</h4>
                <p>Защитете Вашето недвижимо и движимо имущество от непредсказуеми природни бедствия, кражби или пожар. Застраховката се сключва от български, както и от чуждестранни физически и юридически лица за имущество, което се използва в бита и домакинството и се намира на територията на Република България.</p>
                <div className="open-close">
                    <div className={this.state.height[0] === 0 ? 'close' : 'open'} onClick={() => this.toggle(0)}>
                        <div><span className='icon'><img src='/icons/buy.svg' alt='' /></span>Какво е необходимо за закупуване през приложението?</div>
                    </div>
                    <AnimateHeight className='open-close-info' height={this.state.height[0]}>
                        <div>
                            <p>Единственото нещо, от което имате нужда, за да закупите ГО през нашето приложение е малкият талон на автомобила, който искате да застраховате.</p>
                            <p><Link className='color-2' to='/download-app'>Свали приложението</Link></p>
                        </div>
                    </AnimateHeight>
                </div>
                <div className="open-close">
                    <div className={this.state.height[1] === 0 ? 'close' : 'open'} onClick={() => this.toggle(1)}>
                        <div><span className='icon'><img src='/icons/shield.svg' alt='' /></span>Какво е необходимо за добавяне в приложението?</div>
                    </div>
                    <AnimateHeight className='open-close-info' height={this.state.height[1]}>
                        <div>
                            <p>Единственият документ, от който имате нужда, за да добавите съществуваща застраховка в приложението, за да можете да я управлявате и да следите нейните срокове е номерътт на застраховката и да посочите с коя застрахователна компания е сключена</p>
                            <p><Link className='color-2' to='/download-app'>Свали приложението</Link></p>
                        </div>
                    </AnimateHeight>
                </div>

                <h4><span className='icon'><img src='/icons/office.svg' alt='' /></span>За офиси и търговски обекти</h4>
                <p>Застраховат се лични, наети или държани на друго правно основание имущества, които се разделят на:</p>
                <p><b className='base-color'>Недвижимо имущество</b> – административни и търговски сгради</p>
                <p><b className='base-color'>Движимо имущество</b> -  съоръжения, оборудване, материали и суровини</p>
                <div className="open-close">
                    <div className={this.state.height[2] === 0 ? 'close' : 'open'} onClick={() => this.toggle(2)}>
                        <div><span className='icon'><img src='/icons/shield.svg' alt='' /></span>Какво е необходимо за добавяне в приложението?</div>
                    </div>
                    <AnimateHeight className='open-close-info' height={this.state.height[2]}>
                        <div>
                            <p>Единственият документ, от който имате нужда, за да добавите съществуваща застраховка в приложението, за да можете да я управлявате и да следите нейните срокове е номерътт на застраховката и да посочите с коя застрахователна компания е сключена</p>
                            <p><Link className='color-2' to='/download-app'>Свали приложението</Link></p>
                        </div>
                    </AnimateHeight>
                </div>

                <h4><span className='icon'><img src='/icons/tv.svg' alt='' /></span>За предприятия и електронно оборудване</h4>
                <p>Може да застраховате всички видове електронно оборудване, компютри и системи с производствено или търговско предназначение. Покриват се материални загуби, повреди или разноски, възникнали от неочаквани и непредвидени събития.</p>
                <div className="open-close">
                    <div className={this.state.height[3] === 0 ? 'close' : 'open'} onClick={() => this.toggle(3)}>
                        <div><span className='icon'><img src='/icons/shield.svg' alt='' /></span>Какво е необходимо за добавяне в приложението?</div>
                    </div>
                    <AnimateHeight className='open-close-info' height={this.state.height[3]}>
                        <div>
                            <p>Единственият документ, от който имате нужда, за да добавите съществуваща застраховка в приложението, за да можете да я управлявате и да следите нейните срокове е номерътт на застраховката и да посочите с коя застрахователна компания е сключена</p>
                            <p><Link className='color-2' to='/download-app'>Свали приложението</Link></p>
                        </div>
                    </AnimateHeight>
                </div>

                <h4><span className='icon'><img src='/icons/build.svg' alt='' /></span>За строително-монтажни работи</h4>
                <p>Тази услуга Ви позволява да застраховате даден строителен обект:</p>
                <ul>
                    <li>Застрахова се имущество за строително-монтажни работи</li>
                    <li>Покриват се имуществени и неимуществени вреди на трети лица</li>
                    <li>Други извънредни разходи.</li>
                </ul>
                <div className="open-close">
                    <div className={this.state.height[4] === 0 ? 'close' : 'open'} onClick={() => this.toggle(4)}>
                        <div><span className='icon'><img src='/icons/shield.svg' alt='' /></span>Какво е необходимо за добавяне в приложението?</div>
                    </div>
                    <AnimateHeight className='open-close-info' height={this.state.height[4]}>
                        <div>
                            <p>Единственият документ, от който имате нужда, за да добавите съществуваща застраховка в приложението, за да можете да я управлявате и да следите нейните срокове е номерътт на застраховката и да посочите с коя застрахователна компания е сключена</p>
                            <p><Link className='color-2' to='/download-app'>Свали приложението</Link></p>
                        </div>
                    </AnimateHeight>
                </div>

                <h4><span className='icon'><img src='/icons/energy.svg' alt='' /></span>За енергийни източници</h4>
                <p>Застраховайте Вашите различни енергийни източници като ветрогенератори, фотоволтаици и друго оборудване, което се използва за генерирането на екологична енергия. Този вид застраховка имущество покрива щетите Ви от природни бедствия, вандалски прояви, увреждания след удар от превозно средство и др.</p>
                <div className="open-close">
                    <div className={this.state.height[5] === 0 ? 'close' : 'open'} onClick={() => this.toggle(5)}>
                        <div><span className='icon'><img src='/icons/shield.svg' alt='' /></span>Какво е необходимо за добавяне в приложението?</div>
                    </div>
                    <AnimateHeight className='open-close-info' height={this.state.height[5]}>
                        <div>
                            <p>Единственият документ, от който имате нужда, за да добавите съществуваща застраховка в приложението, за да можете да я управлявате и да следите нейните срокове е номерътт на застраховката и да посочите с коя застрахователна компания е сключена</p>
                            <p><Link className='color-2' to='/download-app'>Свали приложението</Link></p>
                        </div>
                    </AnimateHeight>
                </div>

            </div>
        )
    }
}
export default connect()(PropertyIns)