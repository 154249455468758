import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AppsDownload, QR, SectionStatistics, SectionBenefits, Button } from '../../components'
import './styles.scss'

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      docsList: [
        { title: 'Политика за поверителност', link: '/downloads/cookies-policy.pdf', iconSrc: '/icons/privacy.svg', styleName: 'box-link', target: '_blank' },
        { title: 'Условия за ползване', link: '/downloads/tos.pdf', iconSrc: '/icons/contract.svg', styleName: 'box-link', target: '_blank' },
        { title: 'Допълнителна информация', link: '/', iconSrc: '/icons/additional-info.svg', styleName: 'box-link', target: '_blank' },
      ],
      docsListArr: [],
    }
  }

  componentDidMount() {
    this.setState({
      docsListArr: this.state.docsList,
    })
  }

  render() {
    const docsListHTML = this.state.docsListArr.map((docsListArr, index) =>
      <div key={'doc' + index} id={'doc' + index}>
        <Button
          title={docsListArr.title}
          link={docsListArr.link}
          iconSrc={docsListArr.iconSrc}
          target={docsListArr.target}
          styleName={docsListArr.styleName}
        />
      </div>)

    const buttonElements = Array.from(document.querySelectorAll('.title-icon-green'));
    buttonElements.forEach(buttonElement => {
      buttonElement.addEventListener('click', () => {
        const activeElements = Array.from(document.querySelectorAll('.active'));
        activeElements.forEach(activeElement => {
          activeElement.classList.remove('active');
        });
        buttonElement.parentElement.classList.add('active');
      });
    });

    return (
      <div className='home'>
        <div className='section section-main'>
          <div className='container'>
            <div className='row'>
              <div className='col col-1'>
                <div>
                  <h1>АМАРАНТ -<br />Дигиталният Брокер</h1>
                  <h3>ПРЕДИМСТВА:</h3>
                  <ul className='ul'>
                    <li>Проверка на налични глоби към КАТ + заплащане</li>
                    <li>Поръчка на застраховки и плащане на вноски</li>
                    <li>Управление на винетка</li>
                  </ul>
                  <h5>Свали приложението:</h5>
                  <div className='bp-desktop'><AppsDownload /></div>
                  <h5>Или сканирайте QR код с телефона си:</h5>
                  <QR styleName='qr-blue bg-white' />
                </div>
              </div>
              <div className='col col-2'>
                <div className='phones'>
                  <img src='/imgs/amarant-home-main-1.png' alt="Amarant - Магазин" />
                  <img src='/imgs/amarant-home-main-2.png' alt="Amarant - Моите полици" />
                </div>
              </div>

              <div className='col col-3 bp-mobile'>
                <div>
                  <p>Свалете приложението от:</p>
                  <AppsDownload />
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className='section section-why'>
          <div className='container'>
            <div className='row'>
              <div className='col col-1'>
                <h2>ЗАЩО ГО НАПРАВИХМЕ?</h2>
                <p>В това забързано ежедневие и свят, който постоянно <br /> се променя, ние мислим първо за вас!</p>
                <p>Мобилното приложение на Амарант е тук, за да <br /> дигатилизира цялостния процес по грижата за автомобила Ви.</p>
              </div>
              <div className='col col-2'>
                <div className='row row-boxes-list'>
                  <div className='col'>
                    <div className='box'>
                      <div className='icon'><img src='/icons/24-7.svg' alt='24/7' /></div>
                      <h4>24/7<br />Достъп</h4>
                    </div>
                  </div>
                  <div className='col'>
                    <div className='box'>
                      <div className='icon'><img src='/icons/hand-money.svg' alt='Без излишни разходи' /></div>
                      <h4>Без излишни<br />Разходи</h4>
                    </div>
                  </div>
                  <div className='col'>
                    <div className='box'>
                      <div className='icon'><img src='/icons/reminder.svg' alt='Напомняне за срокове' /></div>
                      <h4>Напомняне<br />За срокове</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <SectionBenefits />
        <SectionStatistics />

        <div className='section section-security'>
          <div className='container'>
            <div className='row'>
              <div className='col col-1'>
                <h2>СИГУРНОСТ ЗА ВАШИТЕ ДАННИ</h2>
                <p>Ние от Амарант подхождаме с огромна отговорност към всички данни, които ни предоставяте и спазваме всички закони и наредби за тяхното съхранение.</p>
                <p>Повече може да прочетете тук:</p>
              </div>
              <div className='col col-2'>
                <div className='docs-list'>
                  {docsListHTML}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

export default connect()(Home)