import React, { Component } from 'react';
import './styles.scss';

class AppsDownload extends Component {
  render() {
    return (
      <div className='row row-apps-download'>
        <div className='col'>
          <a className='app-store' href='https://apps.apple.com/us/app/amarant-bulgaria/id1506724182' target="_blank" rel="noopener noreferrer"><img src='/imgs/download-app-store.svg' alt='AppStore' /></a>
        </div>
        <div className='col'>
          <a className='google-play' href='https://play.google.com/store/apps/details?id=com.amarantapp&hl'  target="_blank" rel="noopener noreferrer"><img src='/imgs/download-google-play.svg'  alt='Google Play' /></a>
      </div>
      </div >
    );
  }
}

export default AppsDownload;