import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FaqsListInfo0, FaqsListInfo1, FaqsListInfo2, FaqsListInfo3, FaqsListInfo4, FaqsListInfo5, FaqsListInfo6, FaqsListInfo7, FaqsListInfo8 } from '../FAQs'
import './styles.scss';

class FAQsDesktop extends Component {

  constructor(props) {
    super(props);
    this.state = {
      faqsList: [
        { title: 'Лични Спешни' },
        { title: 'Относно приложението' },
        { title: 'Регистрацията в приложението' },
        { title: 'Застраховки с приложението' },
        { title: 'Завеждане на щета' },
        { title: 'За Е-магазина' },
        { title: 'Моя профил' },
        { title: 'Съдействие' },
        { title: 'Правна информация' },

        // { title: 'Генерални въпроси' },
        // { title: 'Амарант - Брокер' },
        // { title: 'Възможности на приложението' },
        // { title: 'Автомобилни застраховки' },
        // { title: 'Имуществени застраховки' },
        // { title: 'Годишен технически преглед' },
        // { title: 'Други застраховки' },
        // { title: 'Завеждане на щета' },
      ],
      faqsListArr: [],

      height: {
        0: 0, 1: 0, 2: 0, 3: 0, 4: 0,
      },

    }
  }

  componentDidMount() {
    this.setState({
      faqsListArr: this.state.faqsList
    })
    document.getElementsByClassName('faqs-list-info')[0].className = 'faqs-list-info faqs-list-info-active';
  }

  toggle = (index) => {
    const { height } = this.state;
    height[index] = height[index] === 0 ? 'auto' : 0
    this.setState({
      height: height
    });
  };

  faqsToggle = (id) => {
    let faqsList = document.getElementById('faqs-list').childNodes;
    let faqsListInfo = document.getElementsByClassName('faqs-list-info');
    for (let i = 0; i < faqsList.length; i++) {
      if (faqsListInfo[i]) {
        if (id === i) {
          faqsListInfo[i].className = 'faqs-list-info faqs-list-info-active';
          faqsList[i].childNodes[0].className='box-link active'
        } else {
          faqsListInfo[i].className = 'faqs-list-info';
          faqsList[i].childNodes[0].className='box-link'
        }
      }
    }
  }

  render() {

    const faqsListHTML = this.state.faqsListArr.map((faqsListArr, index) =>
      <div key={'faq-' + index} id={'faq-' + index} onClick={() => this.faqsToggle(index)}>
        <div className={(index === 0) ? 'box-link active' : 'box-link'}>
          <span>
            <span className='title'>{faqsListArr.title}</span>
          </span>
        </div>
      </div>)

    // const { height } = this.state;

    return (
      <div className="faqs">
        <div className="section section-head">
          <div className='container'>
            <h3>Често задавани въпроси</h3>
          </div>
        </div>
        <div className="section section-main">
          <div className='container'>
            <div className='row'>

              <div className='col faqs-list' id='faqs-list'>
                {faqsListHTML}
              </div>
              <div className='col faqs-info'>
                <FaqsListInfo0 />
                <FaqsListInfo1 />
                <FaqsListInfo2 />
                <FaqsListInfo3 />
                <FaqsListInfo4 />
                <FaqsListInfo5 />
                <FaqsListInfo6/>
                <FaqsListInfo7/>
                <FaqsListInfo8/>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }

}

export default connect()(FAQsDesktop)