import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { ConnectedRouter } from 'react-router-redux'
import { Route, Switch } from 'react-router'
import { withRouter } from 'react-router-dom'
// import PrivateRoute from './PrivateRoute'
// import {history} from '../../config/stores'
// import { routes } from '../../config/routes'

import { Header, Footer, ScrollToTopRoutes } from '../../components'
import { Home, AboutUs, Insurances, Offices,  Documents, FAQs, DownloadApp } from '../../screens'
// Blog, BlogDetails, BlogCategory,
// import { withRouter } from 'react-router-dom'

class Routes extends Component {
  render() {
    return (
      <div className='wrap-all'>
        {/* <TransitionGroup>
          <CSSTransition classNames='fade' enter leave timeout={1000}> */}
        <ScrollToTopRoutes />
        <Header />
        <Switch>
          <Route exact path='/' component={Home} />
          <Route path='/about-us' component={AboutUs} />
          <Route path='/insurances' component={Insurances} />
          <Route path='/offices' component={Offices} />
          {/* <Route path='/blog/details' component={BlogDetails} />
          <Route path='/blog/category' component={BlogCategory} />
          <Route path='/blog' component={Blog} /> */}
          <Route path='/documents' component={Documents} />
          <Route path='/faqs' component={FAQs} />
          <Route path='/download-app' component={DownloadApp} />
        </Switch>
        <Footer />

        {/* </CSSTransition>
        </TransitionGroup> */}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { loading: state.starter.loading }
}

export default withRouter(connect(mapStateToProps)(Routes))

