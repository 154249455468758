import React from 'react'
import { connect } from 'react-redux'
import { ProfessionalResp, AgriculturalIns, TransportIns, PropertyIns, CarIns, MedicalIns, LifeIns, TravelCancellation, Accident } from './index'

import './styles.scss'

class InsurancesDesktop extends React.Component {
  state = {
    tabs: [
      { name: 'ПРОФЕСИОНАЛНИ ОТГОВОРНОСИ', icon: '/icons/professional-resp.svg', activation: 'professionalResp' },
      { name: 'СЕЛСКОСТОПАНСКИ ЗАСТРАХОВКИ', icon: '/icons/agricultural-ins.svg', activation: 'agriculturalIns' },
      { name: 'ТРАНСПОРТНИ ЗАСТРАХОВКИ', icon: '/icons/transport.svg', activation: 'transportIns' },
      { name: 'ЗАСТРАХОВКА ИМУЩЕСТВО', icon: '/icons/property-ins.svg', activation: 'propertyIns' },
      { name: 'АВТОМОБИЛНИ ЗАСТРАХОВКИ', icon: '/icons/car-ins.svg', activation: 'carIns' },
      { name: 'МЕДИЦИСНКСКИ И ТУРИСТИЧЕСКИ ЗАСТРАХОВКИ', icon: '/icons/medical-ins.svg', activation: 'medicalIns' },
      { name: 'ЗАСТРАХОВКА ЖИВОТ', icon: '/icons/life-ins.svg', activation: 'lifeIns' },
      { name: 'ЗАСТРАХОВКА ОТМЯНА НА ПЪТУВАНЕ', icon: '/icons/travel-cancellation.svg', activation: 'travelCancellation' },
      { name: 'ЗЛОПОЛУКА', icon: '/icons/accident.svg', activation: 'accident' },
    ],
  }

  componentDidMount() {
    this.setState({
      insurances: this.state.insuranceAccident
    })
    const tabsCount = Math.round(this.state.tabs.length / 2) - 1; // select the middle element
    document.getElementById(this.state.tabs[tabsCount].activation).className = 'tab active-tab';
    document.getElementById(this.state.tabs[tabsCount].activation + 'Info').className = 'tab-info show'
  }

  render() {

    function switchMenu(index) {
      var elId = document.getElementById(this.state.tabs[index].activation).id;
      var elIdInfo = elId + 'Info';
      for (var i = 0; i < document.getElementsByClassName('tab').length; i++) {
        document.getElementsByClassName('tab')[i].className = 'tab'
      }
      for (var j = 0; j < document.getElementsByClassName('tab-info').length; j++) {
        document.getElementsByClassName('tab-info')[j].className = 'tab-info'
      }
      document.getElementById(elId).className = 'tab active-tab'
      document.getElementById(elIdInfo).className = 'tab-info show'

    }

    const tabsArrHTML = this.state.tabs.map((tabs, index) =>
      <div key={'tab' + index} id={this.state.tabs[index].activation} className='tab' onClick={switchMenu.bind(this, index)}>
        <div>
          <span className='icon'><img src={tabs.icon} alt='' /></span>
          <span className='name'>{tabs.name}</span>
        </div>
      </div>
    )

    return (

      <div className="insurances">
        <div className="section section-main">
          <div className="row">

            <div className="col col-1">
              <div>
                <div className='insurances-list'>
                  {tabsArrHTML}
                </div>
              </div>


            </div>
            <div className="col col-2">
              <div className="insurances-info">

                <ProfessionalResp />
                <AgriculturalIns />
                <TransportIns />
                <PropertyIns />
                <CarIns />
                <MedicalIns />
                <LifeIns />
                <TravelCancellation />
                <Accident />

              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }
}
export default connect()(InsurancesDesktop)