import React, { Component } from 'react'
import { connect } from 'react-redux'
import AnimateHeight from 'react-animate-height';

class FaqsListInfo8 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: {
                0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0,
            },

        }
    }

    toggle = (index) => {
        const { height } = this.state;
        height[index] = height[index] === 0 ? 'auto' : 0
        this.setState({
            height: height
        });
    };

    render() {
        // const { height } = this.state;

        return (
            <div className='faqs-list-info'>

                <div className='faq-info'>
                    <div className={this.state.height[0] === 0 ? 'open' : 'close'} onClick={() => this.toggle(0)}>
                        <div>Мога ли да използвам регистрацията си на повече от едно мобилно устройство?</div>
                    </div>
                    <AnimateHeight height={this.state.height[0]} >
                        <div>
                            <p>Да, може да се използва Вашата регистрация на чужди устройства, стига да напишете 4-цифрения код, който се изпраща само на Вашето мобилно устройство. </p>
                            <p>При влизане в профила Ви от друго устройство, директно се излиза от профила Ви на Вашето устройство!</p>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[1] === 0 ? 'open' : 'close'} onClick={() => this.toggle(1)}>
                        <div>Може ли да се извърши онлайн плащане?</div>
                    </div>
                    <AnimateHeight height={this.state.height[1]} >
                        <div>
                            <p>Да, можете да платите онлайн чрез VISA § Mastercard и да извършите Вашата транзакция на момента като за всички кредитни и дебитни карти таксата е 00,00лв. При желание, запаметете(снимка) и използвайте Вашата карта  за следващи плащания!</p>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[2] === 0 ? 'open' : 'close'} onClick={() => this.toggle(2)}>
                        <div>Мога ли да възобновя изтрити данни?</div>
                    </div>
                    <AnimateHeight height={this.state.height[2]} >
                        <div>
                            <p>След потвърждение на съобщението: (снимка) „Сигурни ли сте, че искате да изтриете данните?“, данните не могат да се възобновят.</p>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[3] === 0 ? 'open' : 'close'} onClick={() => this.toggle(3)}>
                        <div>Мога ли да споделя приложението?</div>
                    </div>
                    <AnimateHeight height={this.state.height[3]} >
                        <div>
                            <p>Разбира се, само трябва да отидете на своя профил и до секция  „Съдействие“, където се избира  „Покани приятел“ или просто натиснете тук за директно споделяне : линк за споделяне</p>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[4] === 0 ? 'open' : 'close'} onClick={() => this.toggle(4)}>
                        <div>Мога ли да купя застаховка на друг човек?</div>
                    </div>
                    <AnimateHeight height={this.state.height[4]} >
                        <div>
                            <p>Да, можете и при това доста лесно, необходими са само неговите данни и разрешение, за да го направите в приложението!</p>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[5] === 0 ? 'open' : 'close'} onClick={() => this.toggle(5)}>
                        <div>Какви застрахователни операции мога да извършвам през мобилното приложение?</div>
                    </div>
                    <AnimateHeight height={this.state.height[5]} >
                        <div>
                            <p>Можете да получите индивидуална оферта за застраховки Гражданска отговорност, Имущество и други, да заплатите поредна вноска по вече сключена застраховка, да въведете вече съществуващите си полици, за да може мобилното приложение да ви уведомява за приближаващи вноски.</p>
                            <ul className='ul'>
                                <li>Закупуване на полици</li>
                                <li>Вноска по полица</li>
                                <li>Въвеждане вече съществуваща полица – вноска/плащане</li>
                            </ul>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[6] === 0 ? 'open' : 'close'} onClick={() => this.toggle(6)}>
                        <div>Мога ли да прекратя/изтрия възлагателен договор?</div>
                    </div>
                    <AnimateHeight height={this.state.height[6]} >
                        <div>
                            <p>Можете да прекратите договора за възлагане едностранно с писменно двумесечно предизвестие на нашия мейл office@amarantbg.com или чрез друга писмена форма на административния ни адрес: гр.София ул. Хан Аспарух 5</p>
                        </div>
                    </AnimateHeight>
                </div>

            </div>
        )
    }

}

export default connect()(FaqsListInfo8)