import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import './styles.scss'

class NavMain extends Component {
  render() {
    return (
      <div className='nav-main-wrap'>
        <nav className="nav-main">
          <div className="item"><NavLink activeClassName="active" to='/insurances'>ЗАСТРАХОВКИ</NavLink></div>
          <div className="item"><NavLink activeClassName="active" to='/offices'>ОФИСИ</NavLink></div>
          <div className="item"><NavLink activeClassName="active" to='/about-us'>ЗА НАС</NavLink></div>
          {/* <div className="item"><NavLink activeClassName="active" to='/blog'>БЛОГ</NavLink></div> */}
          <div className="item"><NavLink className='item-bttn' to='/download-app'>Свали приложението</NavLink></div>
        </nav>
      </div>
    );
  }
}

export default connect()(withRouter(NavMain));
