import React, { Component } from 'react'
import { connect } from 'react-redux'
import AnimateHeight from 'react-animate-height';

class FaqsListInfo4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: {
                0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0,
            },

        }
    }

    toggle = (index) => {
        const { height } = this.state;
        height[index] = height[index] === 0 ? 'auto' : 0
        this.setState({
            height: height
        });
    };

    render() {
        // const { height } = this.state;

        return (
            <div className='faqs-list-info'>

                <div className='faq-info'>
                    <div className={this.state.height[0] === 0 ? 'open' : 'close'} onClick={() => this.toggle(0)}>
                        <div>A.Защо няма завеждане на щета в приложението?</div>
                    </div>
                    <AnimateHeight height={this.state.height[0]} >
                        <div>
                            <p>Ние от Амарант сме перфекционисти и желаем да изпипваме всичко до последния детайл. Затова и се нуждаем от още малко време, за да достигнем желаната от нас цел в създаването на меню „Завеждане на щета“ , добавяйки още 1 иноваторска опция към многото такива в мобилното приложение „Амарант“ !</p>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[1] === 0 ? 'open' : 'close'} onClick={() => this.toggle(1)}>
                        <div>Какво представлява правната помощ при щета?</div>
                    </div>
                    <AnimateHeight height={this.state.height[1]} >
                        <div>
                            <p>При нужда от съдействие от юрист при застрахователно събитие, можете да се свържете с нас. ( неоснователен отказ за изплащане на обезщетение, нужда от съдействие за събиране на необходимите документи за завеждане на събитие и други).</p>
                        </div>
                    </AnimateHeight>
                </div>

            </div>
        )
    }

}

export default connect()(FaqsListInfo4)