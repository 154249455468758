import React, { Component } from 'react';
import './styles.scss';

class BannerBAZB extends Component {
  render() {
    return (
      <div className='section section-banner-bazb'>
        <div className='container'>
          Член на:<img className='logo-bazb' src='/imgs/logo-bazb.svg' alt="БАЗБ" />
          <span className="txt">Българска Асоциация на Застрахователните Брокери (БАЗБ)</span>
        </div>
      </div>
    );
  }
}

export default BannerBAZB;