import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withGetScreen } from 'react-getscreen'
import { BenefitVideo2 } from '../SectionBenefits'

class BenefitInfo2 extends Component {
    render() {
        return (
            <div className='benefit-info'>
                <p>През приложението може да закупите застрахователна полица бързо и лесно, без чакане.</p>
                <ul className='ul'>
                    <li>Изберете оферта за Гражданска Отговорност от 10-те водещи застрахователи</li>
                    <li>Платете с карта или наложен платеж</li>
                    <li>Влизане в сила от момента на поръчката</li>
                </ul>
                { this.props.isMobile() ? <div><BenefitVideo2/></div> : "" }
            </div>
        )
    }
}

const options = { mobileLimit: 1024, shouldListenOnResize: true  }
export default connect()(withGetScreen(BenefitInfo2, options))
