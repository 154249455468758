import React, { Component } from 'react';
import './styles.scss';
class Socials extends Component {
    render() {
        return (
            <div className='socials'>
                <div className='container'>
                    <div className='socials-list'>
                        <span><a href='https://www.facebook.com/amarantbg/' target='_blank' rel="noopener noreferrer"><img src='/icons/facebook.svg' alt='Амарант България - Facebook' /></a></span>
                        <span><a href='javasscript:void(0)' target='_blank' rel="noopener noreferrer"><img src='/icons/instagram.svg' alt='Амарант България - Instagram' /></a></span>
                    </div>
                </div>
            </div>
        )
    }
}
export default Socials;