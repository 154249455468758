import React, { Component } from 'react'
import { connect } from 'react-redux'
import AnimateHeight from 'react-animate-height';

class FaqsListInfo1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: {
                0: 0, 1: 0, 2: 0, 3: 0, 4: 0,
            },

        }
    }

    toggle = (index) => {
        const { height } = this.state;
        height[index] = height[index] === 0 ? 'auto' : 0
        this.setState({
            height: height
        });
    };

    render() {
        // const { height } = this.state;
        return (
            <div className='faqs-list-info'>

                <div className='faq-info'>
                    <div className={this.state.height[0] === 0 ? 'open' : 'close'} onClick={() => this.toggle(0)}>
                        <div>Какво се случва, ако съм купил застраховка с грешно попълнени данни?</div>
                    </div>
                    <AnimateHeight height={this.state.height[0]} >
                        <div>
                            <p>Сигнализирайте ни незабавно след установяване на грешката на телефон 0700..... В зависимост от това в коя информация е допусната грешката и влязла ли е в сила застраховката има два варианта:</p>
                            <ul className='ul'>
                                <li>Издаване на анекс за промяна на данните на полицата Ви.</li>
                                <li>Прекратяване на полицата Ви.</li>
                            </ul>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[1] === 0 ? 'open' : 'close'} onClick={() => this.toggle(1)}>
                        <div>Има ли възможност приложението да не отчете съществуваща глоба на КАТ?</div>
                    </div>
                    <AnimateHeight height={this.state.height[1]} >
                        <div>
                            <p>Няма възможност за неотчитане на съществуваща глоба, понеже приложението „Амарант“ е директен посредник между Вас и КАТ. По-вероятно е да нямате глоба в самата система на КАТ.</p>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[2] === 0 ? 'open' : 'close'} onClick={() => this.toggle(2)}>
                        <div>Платих с банкова карта, но не получавам полицата на мейла.Какво да направя сега?</div>
                    </div>
                    <AnimateHeight height={this.state.height[2]} >
                        <div>
                            <p>При възникване на следната ситуация, Ви препоръчваме да се свържете с:</p>
                            <ul className='ul'>
                                <li>Наш посредник на телефон: 08******** за решение на момента</li>
                                <li>Да опишете Вашия проблем и изпратите на: ХХХХХ@fidweb.net за отговор до 24 часа</li>
                            </ul>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[3] === 0 ? 'open' : 'close'} onClick={() => this.toggle(3)}>
                        <div>Поръчах си застраховка с наложен платеж, но не я получавам. Кога ще дойде куриерът?</div>
                    </div>
                    <AnimateHeight height={this.state.height[3]} >
                        <div>
                            <p>При възникване на следната ситуация, Ви препоръчваме да се свържете с:</p>
                            <ul className='ul'>
                                <li>Наш посредник на телефон: 08******** за решение на момента</li>
                                <li>Да опишете Вашия проблем и изпратите на: ХХХХХ@fidweb.net за отговор до 24 часа</li>
                            </ul>
                        </div>
                    </AnimateHeight>
                </div>

            </div>
        )
    }

}

export default connect()(FaqsListInfo1)