import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TransitionGroup, CSSTransition } from "react-transition-group";
import {
  BenefitInfo1, BenefitInfo2, BenefitInfo3, BenefitInfo4,
  BenefitVideo1, BenefitVideo2, BenefitVideo3, BenefitVideo4
} from '../SectionBenefits'

import './styles.scss'

class SectionBenefits extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      benefitsList: [
        { title: 'БЪРЗА ПРОВЕРКА', icon: '/icons/clock.svg' },
        { title: 'КУПИ ЗАСТРАХОВКА', icon: '/icons/tick-shield.svg' },
        { title: 'ДОБАВИ СЪЩЕСТВУВАЩА ПОЛИЦА', icon: '/icons/tick-shield-add.svg' },
        { title: 'ОНЛАЙН МАГАЗИН', icon: '/icons/point-finger.svg' },
      ],
      benefitsListArr: [],
    };
  }

  componentDidMount() {
    this.setState({
      selectedIndex: this.state.selectedIndex,
      benefitsListArr: this.state.benefitsList
    })
  }

  benefitsToggle = (id) => {
    let benefitsList = document.getElementById('benefits-list').childNodes;
    for (let i = 0; i < benefitsList.length; i++) {
      if (id === i) {
        benefitsList[i].className = 'benefit active'
        this.setState({ selectedIndex: id })
      } else {
        benefitsList[i].className = 'benefit'
      }
    }
  }

  render() {
    // const { selectedIndex } = this.state;
    const { onClose } = this.props;

    const benefitsListHTML = this.state.benefitsListArr.map((benefitsListArr, index) =>
      <div key={'benefit-' + index} id={'benefit-' + index} className={(index === 0) ? 'benefit active' : 'benefit'} >
        <div className='benefit-title' onClick={() => this.benefitsToggle(index)}>
          <div className='icon'><img src={benefitsListArr.icon} alt='' /></div>
          <div className='title'>{benefitsListArr.title}</div>
        </div>
        <div className='benefit-content'>
          {(index === 0) ? <BenefitInfo1 /> : ''}
          {(index === 1) ? <BenefitInfo2 /> : ''}
          {(index === 2) ? <BenefitInfo3 /> : ''}
          {(index === 3) ? <BenefitInfo4 /> : ''}
        </div>
      </div>)

    return (
      <div className="section section-benefits">
        <div className="container">
          <div className="row row-title">
            <div className="col"><h2 className='txt-center'>KAKВИ СА ПОЛЗИТЕ?</h2></div>
          </div>

          <div className="row row-benefits">
            <div className="col col-1">

              <div className="benefit-video">
                <TransitionGroup component={null}>
                  {this.state.selectedIndex === 0 ?
                    <CSSTransition classNames="dialog" timeout={300}>
                      <div className="dialog--overlay" onClick={onClose}>
                        <div className="video-wrap"><BenefitVideo1 /></div>
                      </div>
                    </CSSTransition>
                    : null}
                  
                  {this.state.selectedIndex === 1 ?
                    <CSSTransition classNames="dialog" timeout={300}>
                      <div className="dialog--overlay" onClick={onClose}>
                        <div className="video-wrap"><BenefitVideo2 /></div>
                      </div>
                    </CSSTransition>
                    : null}
                    {this.state.selectedIndex === 2 ?
                    <CSSTransition classNames="dialog" timeout={300}>
                      <div className="dialog--overlay" onClick={onClose}>
                        <div className="video-wrap"><BenefitVideo3 /></div>
                      </div>
                    </CSSTransition>
                    : null}
                    {this.state.selectedIndex === 3 ?
                    <CSSTransition classNames="dialog" timeout={300}>
                      <div className="dialog--overlay" onClick={onClose}>
                        <div className="video-wrap"><BenefitVideo4 /></div>
                      </div>
                    </CSSTransition>
                    : null}
                </TransitionGroup>
              </div>

            </div>
            <div className="col col-2">
              <h3>Основни функции</h3>
              <p>Приложението е създадено с мисъл за Вас. То е вашият дигитален брокер, виртуален портфейл и верен асистент! С него забравяте за грижите, висенето по опашки и неразбираемата информация!</p>

              <div className='benefits-list' id='benefits-list'>
                {benefitsListHTML}
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }

}

export default connect()(SectionBenefits)