import { createStore, applyMiddleware } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import logger from 'redux-logger'
import { createBrowserHistory } from 'history'
// import createHistory from 'history/createBrowserHistory'
//Please use `require("history").createBrowserHistory` instead of `require("history/createBrowserHistory")`. Support for the latter will be removed in the next major release.


import reducers from '../reducers'

import { createEpicMiddleware } from 'redux-observable'
import { rootEpic } from '../epics'

const epicMiddleware = createEpicMiddleware()
const history = createBrowserHistory();
// const history = createHistory.createBrowserHistory();
// const history = createHistory()

const middleware = []
middleware.push(logger)
middleware.push(routerMiddleware(history))
middleware.push(epicMiddleware)

// const store = createStore(
//   reducers,
//   applyMiddleware(...middleware)
// )

const configureStore = () => {
  const store = createStore(
    reducers,
    applyMiddleware(...middleware)
  )

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers/index')
      store.replaceReducer(nextRootReducer)
    })
  }

  epicMiddleware.run(rootEpic)
  return store
}

export {
  configureStore,
  history
}
