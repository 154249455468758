import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import AnimateHeight from 'react-animate-height';

class AgriculturalIns extends React.Component {
  state = {
    height: {
      0: 0, 1: 0, 2: 0, 3: 0
    },
  }

  toggle = (index) => {
    const { height } = this.state;
    height[index] = height[index] === 0 ? 'auto' : 0
    this.setState({
      height: height,
    });
  };

  render() {

    return (
      <div className='tab-info' id='agriculturalInsInfo'>
        <h4><span className='icon'><img src='/icons/crops.svg' alt='' /></span>Земеделски култури</h4>
        <p>Тази застраховка осигурява широка защита на реколтата от земеделски култури срещу природни бедствия.</p>
        <div className="open-close">
          <div className={this.state.height[0] === 0 ? 'close' : 'open'} onClick={() => this.toggle(0)}>
            <div><span className='icon'><img src='/icons/shield.svg' alt='' /></span>Какво е необходимо за добавяне в приложението?</div>
          </div>
          <AnimateHeight className='open-close-info' height={this.state.height[0]}>
            <div>
              <p>Единственият документ, от който имате нужда, за да добавите съществуваща застраховка в приложението, за да можете да я управлявате и да следите нейните срокове е номерът на застраховката и да посочите с коя застрахователна компания е сключена</p>
              <p><Link className='color-2' to='/download-app'>Свали приложението</Link></p>
            </div>
          </AnimateHeight>
        </div>

        <h4><span className='icon'><img src='/icons/perennials.svg' alt='' /></span>Трайни насаждения</h4>
        <p>Обект на покритие е самото растение. Продуктът позволява на земеделците да получават средства за възтановяване на увредени, от застраховани рискове, многогодишни насаждения.</p>
        <div className="open-close">
          <div className={this.state.height[1] === 0 ? 'close' : 'open'} onClick={() => this.toggle(1)}>
            <div><span className='icon'><img src='/icons/shield.svg' alt='' /></span>Какво е необходимо за добавяне в приложението?</div>
          </div>
          <AnimateHeight className='open-close-info' height={this.state.height[1]}>
            <div>
              <p>Единственият документ, от който имате нужда, за да добавите съществуваща застраховка в приложението, за да можете да я управлявате и да следите нейните срокове е номерът на застраховката и да посочите с коя застрахователна компания е сключена</p>
              <p><Link className='color-2' to='/download-app'>Свали приложението</Link></p>
            </div>
          </AnimateHeight>
        </div>

        <h4><span className='icon'><img src='/icons/pets.svg' alt='' /></span>Домашни и селскостопански животни</h4>
        <p>Основно предназначение на застраховането на домашни животни е да предостави на застрахованите парични компенсации за нанесените им щети от застрахователни събития и да възобнови нормалния ритъм на даденото животновъдство.</p>
        <div className="open-close">
          <div className={this.state.height[2] === 0 ? 'close' : 'open'} onClick={() => this.toggle(2)}>
            <div><span className='icon'><img src='/icons/shield.svg' alt='' /></span>Какво е необходимо за добавяне в приложението?</div>
          </div>
          <AnimateHeight className='open-close-info' height={this.state.height[2]}>
            <div>
              <p>Единственият документ, от който имате нужда, за да добавите съществуваща застраховка в приложението, за да можете да я управлявате и да следите нейните срокове е номерът на застраховката и да посочите с коя застрахователна компания е сключена</p>
              <p><Link className='color-2' to='/download-app'>Свали приложението</Link></p>
            </div>
          </AnimateHeight>
        </div>
        
      </div>
    )
  }
}
export default connect()(AgriculturalIns)