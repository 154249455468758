import React, { Component } from 'react';
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { AppsDownload, Socials } from '../../components'
import { GMap} from '../Footer'
import './styles.scss';

class Footer extends Component {
  render() {
    return (
      <div className='footer'>
        <div className='container'>
          <div className="row">
            <div className="col col-1">
              <div>
                <div className="logo"><img src='/amarant-logo.svg' alt='Амарант България' /></div>
                <p>Дигитален застрахователен брокер на България</p>
                <AppsDownload />
              </div>
            </div>
            <div className="col col-2">
              <div>
                <h4>Полезна информация</h4>
                <ul className='nav-footer'>
                  {/* <li><NavLink activeClassName="active" to='/payment-method'>Начин на плащане</NavLink></li>
                  <li><NavLink activeClassName="active" to='/law'>Правни въпроси</NavLink></li> */}
                  <li><NavLink activeClassName="active" to='/documents'>Документи</NavLink></li>
                  <li><NavLink activeClassName="active" to='/faqs'>Често задавани въпроси</NavLink></li>
                </ul>
              </div>
            </div>
            <div className="col col-3">
              <div>
                <h4>Централен офис АМАРАНТ</h4>
                <p><span className='icon'><img src='../icons/address-pin.svg' alt='адрес' /></span>ул. Хан Аспарух 5, гр. София</p>
                <p><span className='icon'><img src='../icons/address-mail.svg' alt='мейл' /></span>office@amarantbg.com</p>
                <p className='p-phones'><span className='icon'><img src='../icons/address-phone.svg' alt='телефон' /></span><span>02/492 13 53</span><span>02/492 13 55</span></p>
              </div>
            </div>
            <div className="col col-4">
              <GMap/>
            </div>
          </div>
        </div>
        <div className='footer-socials'>
          <Socials />
        </div>
      </div>
    );
  }
}

export default connect()(Footer);