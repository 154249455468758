import React, { Component } from 'react'
import { connect } from 'react-redux'
import AnimateHeight from 'react-animate-height';

class FaqsListInfo0 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: {
                0: 0, 1: 0, 2: 0, 3: 0, 4: 0,
            },

        }
    }

    toggle = (index) => {
        const { height } = this.state;
        height[index] = height[index] === 0 ? 'auto' : 0
        this.setState({
            height: height
        });
    };

    render() {
        // const { height } = this.state;

        return (
            <div className='faqs-list-info'>

                <div className='faq-info'>
                    <div className={this.state.height[0] === 0 ? 'open' : 'close'} onClick={() => this.toggle(0)}>
                        <div>Какво да направя, ако изгубя, или откраднат мобилното устройство, на което използвам мобилното приложение?</div>
                    </div>
                    <AnimateHeight height={this.state.height[0]} >
                        <div>
                            <p>При възникване на следната ситуация, Ви препоръчваме да се свържете с Наш посредник на телефон: 08******** за решение на момента.</p>
                            <p>Съществува възможност да влезете с профила си приложението „Амарант“  през чуждо устройство и по този начин да излезете от профила си в минало Ви мобилно устройство. Това може да се случи само след въвеждане на 4-цифрения код, който се получава като СМС от първоначалното устройство! (тоест след намиране на въпросното изгубено/откраднато устройство, или след сменяне на SIM-картата му)</p>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[1] === 0 ? 'open' : 'close'} onClick={() => this.toggle(1)}>
                        <div>Изисква ли се от мен да посетя офис на Амарант след дадена операция в приложението?</div>
                    </div>
                    <AnimateHeight height={this.state.height[1]} >
                        <div>
                            <p>Приложението е създадено с цел всичко да се случва дигитално. При нужда от посещение на офис на Амарант, ще бъдете информирани незабавно и непременно ще се ангажираме с решението на Вашия проблем!</p>
                            <p>Ако искате да бъдете обслужени в наш офис, можете да намерите най-близкият до вас в Меню „Купи застраховка“ – „Намери най-близкия офис на Амарант“.</p>
                        </div>
                    </AnimateHeight>
                </div>

            </div>
        )
    }

}

export default connect()(FaqsListInfo0)