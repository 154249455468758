import React from 'react'
import { connect } from 'react-redux'
import { ProfessionalResp, AgriculturalIns, TransportIns, PropertyIns, CarIns, MedicalIns, LifeIns, TravelCancellation, Accident } from './index'

import './mstyles.scss'

class InsurancesMobile extends React.Component {
  state = {
    listIsOpen: false,
    height: {
      0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0
    },
    id: 4,
    tabs: [
      { name: 'ПРОФЕСИОНАЛНИ ОТГОВОРНОСИ', icon: '/icons/professional-resp.svg', activation: 'professionalResp' },
      { name: 'СЕЛСКОСТОПАНСКИ ЗАСТРАХОВКИ', icon: '/icons/agricultural-ins.svg', activation: 'agriculturalIns' },
      { name: 'ТРАНСПОРТНИ ЗАСТРАХОВКИ', icon: '/icons/transport.svg', activation: 'transportIns' },
      { name: 'ЗАСТРАХОВКА ИМУЩЕСТВО', icon: '/icons/property-ins.svg', activation: 'propertyIns' },
      { name: 'АВТОМОБИЛНИ ЗАСТРАХОВКИ', icon: '/icons/car-ins.svg', activation: 'carIns' },
      { name: 'МЕДИЦИСНКСКИ И ТУРИСТИЧЕСКИ ЗАСТРАХОВКИ', icon: '/icons/medical-ins.svg', activation: 'medicalIns' },
      { name: 'ЗАСТРАХОВКА ЖИВОТ', icon: '/icons/life-ins.svg', activation: 'lifeIns' },
      { name: 'ЗАСТРАХОВКА ОТМЯНА НА ПЪТУВАНЕ', icon: '/icons/travel-cancellation.svg', activation: 'travelCancellation' },
      { name: 'ЗЛОПОЛУКА', icon: '/icons/accident.svg', activation: 'accident' },
    ],
  }

  componentDidMount() {
    // this.setState({
    //   insurances: this.state.insuranceAccident,
    // })
    const mTabsCount = Math.round(this.state.tabs.length / 2) - 1; // select the middle element
    document.getElementById(this.state.tabs[mTabsCount].activation).className = 'tab active-tab';
    document.getElementById(this.state.tabs[mTabsCount].activation + 'Info').className = 'tab-info show'
  }
  closeMenu() {
    this.setState({ listIsOpen: false })
  }
  toggleMenu() {
    this.setState({ listIsOpen: !this.state.listIsOpen });
  }

  render() {
    const toggleListIsOpen = this.state.listIsOpen;
    let toggleListStyles = '';

    if (toggleListIsOpen) {
      toggleListStyles = 'menu-list-toggle isOpen';
      document.body.style.overflow = 'hidden';
    } else {
      toggleListStyles = 'menu-list-toggle';
      document.body.style.overflow = 'auto';
    }
    function switchMenu(index) {
      var elId = document.getElementById(this.state.tabs[index].activation).id;
      var elIdInfo = elId + 'Info';
      for (var i = 0; i < document.getElementsByClassName('tab').length; i++) {
        document.getElementsByClassName('tab')[i].className = 'tab'
      }
      for (var j = 0; j < document.getElementsByClassName('tab-info').length; j++) {
        document.getElementsByClassName('tab-info')[j].className = 'tab-info'
      }
      document.getElementById(elId).className = 'tab active-tab'
      document.getElementById(elIdInfo).className = 'tab-info show'
    }

    const tabsArrHTML = this.state.tabs.map((tabs, index) =>
      <div key={'tab' + index} id={this.state.tabs[index].activation} className='tab' onClick={switchMenu.bind(this, index)}>
        <div onClick={() => this.setState({ id: index })}>
          <span className='icon'><img src={tabs.icon} alt='' /></span>
          <span className='name'>{tabs.name}</span>
        </div>
      </div>
    )
    return (

      <div className="m-insurances">
        <div className="section m-section-main">
          <div className="menu-list">
            <div className="open-box" listIsOpen={this.state.listIsOpen} onClick={this.toggleMenu.bind(this)}>
              <span className="icon"><img src={this.state.tabs[this.state.id].icon} alt='' /></span>
              <span className="name"> {this.state.tabs[this.state.id].name} </span>
            </div>
            <div className='m-insurances-list' onClick={() => this.closeMenu()} className={toggleListStyles} >
              <div className="m-list">
                {tabsArrHTML}
              </div>
            </div>
          </div>
          <div className="m-insurances-info">

            <ProfessionalResp />
            <AgriculturalIns />
            <TransportIns />
            <PropertyIns />
            <CarIns />
            <MedicalIns />
            <LifeIns />
            <TravelCancellation />
            <Accident />

          </div>
        </div>
      </div>
    )
  }
}
export default connect()(InsurancesMobile)