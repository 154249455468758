import React, { Component } from 'react'
import { connect } from 'react-redux'
import AnimateHeight from 'react-animate-height';
import { FaqsListInfo0, FaqsListInfo1, FaqsListInfo2, FaqsListInfo3, FaqsListInfo4, FaqsListInfo5, FaqsListInfo6, FaqsListInfo7, FaqsListInfo8 } from '../FAQs'
import './mstyles.scss';

class FAQsMobile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      faqsList: [
        { title: 'Лични Спешни' },
        { title: 'Относно приложението' },
        { title: 'Регистрацията в приложението' },
        { title: 'Застраховки с приложението' },
        { title: 'Завеждане на щета' },
        { title: 'За Е-магазина' },
        { title: 'Моя профил' },
        { title: 'Съдействие' },
        { title: 'Правна информация' },
      ],
      faqsListArr: [],

      height: {
        0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0,
      },

    }
  }

  componentDidMount() {
    this.setState({
      faqsListArr: this.state.faqsList
    })
  }

  toggle = (index) => {
    const { height } = this.state;
    height[index] = height[index] === 0 ? 'auto' : 0
    this.setState({
      height: height
    });
  };

  render() {

    const faqsListHTML = this.state.faqsListArr.map((faqsListArr, index) =>
      <div key={'faq-' + index} id={'faq-' + index}>
        <div className={this.state.height[index] === 0 ? 'box-link' : 'box-link active'} onClick={() => this.toggle(index)}>
          <span>
            <span className='title'>{faqsListArr.title}</span>
          </span>
        </div>
        <AnimateHeight height={this.state.height[index]} >
          <div className='box-link-info'>
            {(index === 0) ? <FaqsListInfo0 /> : null}
            {(index === 1) ? <FaqsListInfo1 /> : null}
            {(index === 2) ? <FaqsListInfo2 /> : null}
            {(index === 3) ? <FaqsListInfo3 /> : null}
            {(index === 4) ? <FaqsListInfo4 /> : null}
            {(index === 5) ? <FaqsListInfo5 /> : null}
            {(index === 6) ? <FaqsListInfo6 /> : null}
            {(index === 7) ? <FaqsListInfo7 /> : null}
            {(index === 8) ? <FaqsListInfo8 /> : null}
          </div>
        </AnimateHeight>
      </div>)

    // const { height } = this.state;

    return (
      <div className="faqs">
        <div className="section section-head">
          <div className='container'>
            <h3>Често задавани въпроси</h3>
          </div>
        </div>
        <div className="section section-main">
          <div className='container'>
            <div className='row'>

              <div className='col faqs-list-m'>

                {faqsListHTML}

              </div>
              
            </div>
          </div>
        </div>
      </div>
    )
  }

}

export default connect()(FAQsMobile)