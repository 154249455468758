import React, { Component } from 'react'
import { connect } from 'react-redux'

class BenefitVideo2 extends Component {
    render() {
        return (
            <div className='benefit-play' id='benefit-play-2'>
                <a href="https://youtu.be/nLPjyUcFl68" target="_blank">
                    <div className="play">
                        <div className='video-play'><img src='/icons/play-video.svg' alt='' />Гледай видеото</div>
                    </div>
                </a>
                <div className="video">
                    <div>
                        <img src='/imgs/amarant-benefits-2.png' alt='' />
                    </div>
                </div>
            </div>
        )
    }
}


export default connect()(BenefitVideo2)

