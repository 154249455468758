import React, { Component } from 'react'
import { connect } from 'react-redux'
import AnimateHeight from 'react-animate-height';

class FaqsListInfo5 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: {
                0: 0, 1: 0, 2: 0, 3: 0, 4: 0,
            },

        }
    }

    toggle = (index) => {
        const { height } = this.state;
        height[index] = height[index] === 0 ? 'auto' : 0
        this.setState({
            height: height
        });
    };

    render() {
        // const { height } = this.state;

        return (
            <div className='faqs-list-info'>

                <div className='faq-info'>
                    <div className={this.state.height[0] === 0 ? 'open' : 'close'} onClick={() => this.toggle(0)}>
                        <div>Е-винетка</div>
                    </div>
                    <AnimateHeight height={this.state.height[0]} >
                        <div>
                            <p>...</p>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[1] === 0 ? 'open' : 'close'} onClick={() => this.toggle(1)}>
                        <div>Синя/Зелена зона</div>
                    </div>
                    <AnimateHeight height={this.state.height[1]} >
                        <div>
                            <p>...</p>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[2] === 0 ? 'open' : 'close'} onClick={() => this.toggle(2)}>
                        <div>Годишен технически преглед</div>
                    </div>
                    <AnimateHeight height={this.state.height[2]} >
                        <div>
                            <p>1. Какво се случва, ако не отида на запазен час за ГТП?</p>
                            <ul className='ul'><li>Не се притенсявайте, на всекиго се случва да не успее. Затова и Амарант е толерантен и санкционира само честопровиняващите се потребители.</li></ul>
                            <p>2. Когато изтрия час за ГТП, премахва ли се веднага от графика на сервиза?</p>
                            <ul className='ul'><li>Да, можете. Целта е да си запази най-удобен час и място за ГТП и затова оставяме изцяло на Вас свободата за избор кога и къде.</li></ul>
                            <p>3. Мога ли да запазя час за ГТП без да разреша достъп до моето местоположение?</p>
                            <ul className='ul'><li>Да, можете. Целта е да си запази най-удобен час и място за ГТП и затова оставяме изцяло на Вас свободата за избор кога и къде.</li></ul>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[3] === 0 ? 'open' : 'close'} onClick={() => this.toggle(3)}>
                        <div>Глоби КАТ - Какво представляват връчената и невръчената глоба от КАТ?</div>
                    </div>
                    <AnimateHeight height={this.state.height[3]} >
                        <div>
                            <p>Глобите в КАТ стават активни и могат да се плащат след тяхното директно връчване от служител на КАТ, което ги разделя на:</p>
                            <ul className='ul'>
                                <li>Връчени глоби – Глоби, които са вече предадени от служител на КАТ. След този момент, тези глоби са активни и очакват своето плащане в приложението Амарант.</li>
                                <li>Невръчени глоби – Глоби, които все още не са предадени от служител на КАТ и затова те са само видими в приложението, без възможност за плащане.</li>
                            </ul>
                        </div>
                    </AnimateHeight>
                </div>

            </div>
        )
    }

}

export default connect()(FaqsListInfo5)