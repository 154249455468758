import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from '../../components'
import './styles.scss';

class Documents extends Component {

  constructor(props) {
    super(props);
    this.state = {
      docsList: [
        { title: 'Информация по чл.325 от КЗ', link: '/downloads/info-325.pdf', styleName: 'box-link', target: '_blank' },
        { title: 'Двустранен констативен протокол', link: '/downloads/const-prot.pdf', styleName: 'box-link', target: '_blank' },
        { title: 'Указание за попълване на двустранен констативен протокол', link: '/downloads/const-prot-manual.pdf', styleName: 'box-link', target: '_blank' },
        { title: 'Уведомление за поверителност за клиенти', link: '/downloads/clients-privacy.pdf', styleName: 'box-link', target: '_blank' },
        { title: 'Уведомление за поверителност за кандидати', link: '/downloads/candidates-privacy.pdf', styleName: 'box-link', target: '_blank' },
        { title: 'Политика относно бисквитките', link: '/downloads/cookies-policy.pdf', styleName: 'box-link', target: '_blank' },
        { title: 'Общи условия за ползване', link: '/downloads/tos.pdf', styleName: 'box-link', target: '_blank' },
      ],
      docsListArr: [],
    }
  }

  componentDidMount() {
    this.setState({
      docsListArr: this.state.docsList
    })
  }

  render() {

    const docsListHTML = this.state.docsListArr.map((docsListArr, index) =>
      <div key={index} id={index}>
        <Button
          title={docsListArr.title}
          link={docsListArr.link}
          target={docsListArr.target}
          styleName={docsListArr.styleName}
        />
      </div>)

    return (
      <div className="documents">

        <div className="section section-main">
          <div className='container'>
            <div className='row'>
              <div className='col col-1'>
                <div>
                  <h2>Документи</h2>
                  <p>Тук ще намерите информация за важните документи, насоки за попълване и правна информация за вашите данни.</p>
                  <p>Ако не намирате отговор на вашия въпрос, моля погледнете тук:</p>
                  <Button
                    title='Често задавани въпроси'
                    link='faqs'
                    styleName='bttn'
                  />
                </div>
              </div>
              <div className='col col-2'>
                <div className='docs-list'>
                  {docsListHTML}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }

}

export default connect()(Documents)