import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BlogBox, BannerApp } from '../../components'
import './styles.scss';

class BlogDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      blogList: [
        { category: 'Новина', categoryColor: 'blue', date: '09.04.2020г.', title: 'Очаквайте скоро! Нова версия на нашето приложение', annotation: 'С удоволствие споделяме, че за втора поредна година АМАРАНТ бе удостоен с най-престижната награда в застрахователния сектор Застрахователен брокер на годината - Най-динамично развиващо се дружество” за 2019 г.', src: '/blog/blog5.jpg', link: '/', styleName: '' },
        { category: 'Събитие', categoryColor: 'green', date: '22.03.2020г.', title: 'Откриване нов офис в  гр.Пловдив', annotation: 'С удоволствие споделяме, че за втора поредна година АМАРАНТ бе удостоен с най-престижната награда в', src: '/blog/blog2.jpg', link: '/', styleName: '' },
        { category: 'Събитие', categoryColor: 'green', date: '01.01.2020г.', title: 'Амарант през 2020-та година', annotation: 'С удоволствие споделяме, че за втора поредна година АМАРАНТ бе удостоен с най-престижната награда в…', src: '/blog/blog6.jpg', link: '/', styleName: '' },
      ],
      blogListArr: [],
      categoryColors: [
        { categoryId: 'news', categoryColor: 'blue' },
        { categoryId: 'event', categoryColor: 'green' },
        { categoryId: 'team', categoryColor: 'lightblue' }
      ],
      categoryColorsArr: [],
    }
  }

  componentDidMount() {
    this.setState({
      blogListArr: this.state.blogList,
      categoryColorsArr: this.state.categoryColors
    })
  }

  render() {

    const blogListHTML = this.state.blogListArr.map((blogListArr, index) =>
      <div key={index} className='col' id={index}>
        <BlogBox
          category={blogListArr.category}
          categoryColor={blogListArr.categoryColor}
          date={blogListArr.date}
          title={blogListArr.title}
          annotation={blogListArr.annotation}
          src={blogListArr.src}
          link={blogListArr.link}
        />
      </div>)

    return (
      <div className="blog-details">

        <div className="section section-main">
          <div className='container'>
            <div className='row'>
              <div className='col'>
                <div className="category blue"><a href='/blog/category'>Новина</a></div>
                <h3 className="title">Застрахователен брокер на 2019-та година</h3>
                <div className="date">22.06.2020г.</div>
                <div className="blog-img"><a href="/"><img src="/blog/blog1.jpg" alt="" /></a></div>
              </div>
            </div>
          </div>
        </div>

        <div className="section section-content">
          <div className='container'>
            <div className='row'>
              <div className='col'>
                <p>Текстът Lorem ipsum е безсмислен, защото е (непълно) извлечен от пасаж на Цицерон, и затова визуално много прилича на истински – заради разпределението и честотата на срещане на по-къси, средни и дълги думи, разпределението на интервалите и препинателните знаци, както и дължината на изреченията. Разнообразието му осигурява, че ако се случи да бъде размножен, за да изпълни предвиденото пространство, да не се получат шарки от сорта на моарето вследствие еднотипно редуване на думи и интервали. „Налят“ по този начин в графичните блокове, Lorem ipsum позволява на окото да се абстрахира от конкретиката на смисления текст, и да се съсредоточи само върху особеностите на използвания шрифт и неговото визуално въздействие върху читателя.</p>
                <p>Му осигурява, че ако се случи да бъде размножен, за да изпълни предвиденото пространство, да не се получат шарки от сорта на моарето вследствие еднотипно редуване на думи и интервали. „Налят“ по този начин в графичните блокове, Lorem ipsum позволява на окото да се абстрахира от конкретиката на
                смисления текст, и да се съсредоточи само върху особеностите на използвания шрифт и неговото визуално въздействие върху читателя.</p>
                <p>Текстът Lorem ipsum е безсмислен, защото е (непълно) извлечен от пасаж на Цицерон, и затова визуално много прилича на истински – заради разпределението и честотата на срещане на по-къси, средни и дълги думи, разпределението на интервалите и препинателните знаци, както и дължината на изреченията.</p>
                <p>Mу осигурява, че ако се случи да бъде размножен, за да изпълни предвиденото пространство, да не се получат шарки от сорта на моарето вследствие еднотипно редуване на думи и интервали. „Налят“ по този начин в графичните блокове, Lorem ipsum позволява на окото да се абстрахира от конкретиката на смисления текст, и да се съсредоточи само върху особеностите на използвания шрифт и неговото визуално въздействие върху читателя.</p>
              </div>
            </div>
          </div>
        </div>

        <BannerApp />

        <div className="section section-content">
          <div className='container'>
            <div className='row'>
              <div className='col'>
                <p>Текстът Lorem ipsum е безсмислен, защото е (непълно) извлечен от пасаж на Цицерон, и затова визуално много прилича на истински – заради разпределението и честотата на срещане на по-къси, средни и дълги думи, разпределението на интервалите и препинателните знаци, както и дължината на изреченията.</p>
                <p>Mу осигурява, че ако се случи да бъде размножен, за да изпълни предвиденото пространство, да не се получат шарки от сорта на моарето вследствие еднотипно редуване на думи и интервали. „Налят“ по този начин в графичните блокове, Lorem ipsum позволява на окото да се абстрахира от конкретиката на смисления текст, и да се съсредоточи само върху особеностите на използвания шрифт и неговото визуално въздействие върху читателя.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="section section-back">
          <div className='container'>
            <div className='row'>
              <div className='col'>
                <p className='link-back'><a href='/blog' title='Амарант - обратно към блога'><img src='/icons/prev.svg' alt='назад' /> Обратно към Блог</a></p>
              </div>
            </div>
          </div>
        </div>


        <div className="section section-bloglist">
          <div className='container'>
            <div className='row row-headline'>
              <div className='col'>
                <h2 className='uppercase'>Още от нас</h2>
              </div>
            </div>
            <div className='row'>
              {blogListHTML}
            </div>
          </div>
        </div>

      </div>
    )
  }

}

export default connect()(BlogDetails)