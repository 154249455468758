import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BlogBox } from '../../components'
import './styles.scss';

class BlogCategory extends Component {

  constructor(props) {
    super(props);
    this.state = {
      blogList: [
        { category: 'Новина', categoryColor: 'blue', date: '09.04.2020г.', title: 'Очаквайте скоро! Нова версия на нашето приложение', annotation: 'С удоволствие споделяме, че за втора поредна година АМАРАНТ бе удостоен с най-престижната награда в застрахователния сектор Застрахователен брокер на годината - Най-динамично развиващо се дружество” за 2019 г.', src: '/blog/blog5.jpg', link: '/', styleName: '' },
        { category: 'Новина', categoryColor: 'blue', date: '22.03.2020г.', title: 'Откриване нов офис в  гр.Пловдив', annotation: 'С удоволствие споделяме, че за втора поредна година АМАРАНТ бе удостоен с най-престижната награда в', src: '/blog/blog2.jpg', link: '/', styleName: '' },
        { category: 'Новина', categoryColor: 'blue', date: '01.01.2020г.', title: 'Амарант през 2020-та година', annotation: 'С удоволствие споделяме, че за втора поредна година АМАРАНТ бе удостоен с най-престижната награда в…', src: '/blog/blog6.jpg', link: '/', styleName: '' },
        { category: 'Новина', categoryColor: 'blue', date: '09.04.2020г.', title: 'Очаквайте скоро! Нова версия на нашето приложение', annotation: 'С удоволствие споделяме, че за втора поредна година АМАРАНТ бе удостоен с най- престижната награда в застрахователния сектор “Застрахователен брокер на годината - Най-динамично развиващо се дружество” за 2019 г.', src: '/blog/blog5.jpg', link: '/', styleName: '' },
        { category: 'Новина', categoryColor: 'blue', date: '22.03.2020г.', title: 'Откриване нов офис в  гр.Пловдив', annotation: 'С удоволствие споделяме, че за втора поредна година АМАРАНТ бе удостоен с най-престижната награда в', src: '/blog/blog2.jpg', link: '/', styleName: '' },
        { category: 'Новина', categoryColor: 'blue', date: '01.01.2020г.', title: 'Амарант през 2020-та година', annotation: 'С удоволствие споделяме, че за втора поредна година АМАРАНТ бе удостоен с най-престижната награда в…', src: '/blog/blog6.jpg', link: '/', styleName: '' },
      ],
      blogListArr: [],
    }
  }

  componentDidMount() {
    this.setState({
      blogListArr: this.state.blogList
    })
  }

  render() {
    
    const blogListHTML = this.state.blogListArr.map((blogListArr, index) =>
      <div key={index} className='col' id={index}>
        <BlogBox
          category={blogListArr.category}
          categoryColor={blogListArr.categoryColor}
          date={blogListArr.date}
          title={blogListArr.title}
          annotation={blogListArr.annotation}
          src={blogListArr.src}
          link={blogListArr.link}
        />
      </div>)

    return (
      <div className="blog-category">

        <div className="section section-back">
          <div className='container'>
            <div className='row'>
              <div className='col'>
                <p className='link-back'><a href='/blog' title='Амарант - обратно към блога'><img src='/icons/prev.svg' alt='назад' /> Обратно към Блог</a></p>
              </div>
            </div>
          </div>
        </div>

        <div className="section section-bloglist">
          <div className='container'>
            <div className='row row-headline'>
              <div className='colс'>
                <h2 className='uppercase'>Новини</h2>
              </div>
            </div>

            <div className='row'>
              {blogListHTML}
            </div>

            <div className='paging'>
              <div><button className='prev'><img src='/icons/prev.svg' alt='' /></button></div>
              <div><button>1</button></div>
              <div><button className='active'>2</button></div>
              <div><button>3</button></div>
              <div><button>4</button></div>
              <div><button className='next'><img src='/icons/next.svg' alt='' /></button></div>
            </div>

          </div>
        </div>
      </div>
    )
  }

}

export default connect()(BlogCategory)