import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import AnimateHeight from 'react-animate-height';

class ProfessionalResp extends React.Component {
  state = {
    height: {
      0: 0
    },
  }

  toggle = (index) => {
    const { height } = this.state;
    height[index] = height[index] === 0 ? 'auto' : 0
    this.setState({
      height: height,
    });
  };

  render() {

    return (
      <div className='tab-info' id='professionalRespInfo'>
        <h4><span className='icon'><img src='/icons/professional-white.svg' alt='' /></span>Професионални Отговорности</h4>
        <p>Застраховката покрива вреди, причинени от Вас на трети лица, вследствие неизпълнение на задълженията Ви при упражняване на определена професия или оказване на определен вид професионална услуга, за която притежавате съответната правоспособност.</p>
        <p>Голяма част от застраховките "Професионална отговорност“ са задължителни по силата на действащото законодателство.</p>
        <div className="open-close">
          <div className={this.state.height[0] === 0 ? 'close' : 'open'} onClick={() => this.toggle(0)}>
            <div><span className='icon'><img src='/icons/shield.svg' alt='' /></span>Какво е необходимо за добавяне в приложението?</div>
          </div>
          <AnimateHeight className='open-close-info' height={this.state.height[0]}>
            <div>
              <p>Единственият документ, от който имате нужда, за да добавите съществуваща застраховка в приложението, за да можете да я управлявате и да следите нейните срокове е номерътт на застраховката и да посочите с коя застрахователна компания е сключена</p>
              <p><Link className='color-2' to='/download-app'>Свали приложението</Link></p>
            </div>
          </AnimateHeight>
        </div>
      </div>
    )
  }
}
export default connect()(ProfessionalResp)