import React, { Component } from 'react'
import { connect } from 'react-redux'
import AnimateHeight from 'react-animate-height';
import { Link } from 'react-router-dom'

class FaqsListInfo7 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: {
                0: 0, 1: 0, 2: 0, 3: 0, 4: 0,
            },

        }
    }

    toggle = (index) => {
        const { height } = this.state;
        height[index] = height[index] === 0 ? 'auto' : 0
        this.setState({
            height: height
        });
    };

    render() {
        // const { height } = this.state;

        return (
            <div className='faqs-list-info'>

                <div className='faq-info'>
                    <div className={this.state.height[0] === 0 ? 'open' : 'close'} onClick={() => this.toggle(0)}>
                        <div>Къде мога да намеря повече информация за процесите в приложението, освен в секция „Насоки за ползване“?</div>
                    </div>
                    <AnimateHeight height={this.state.height[0]} >
                        <div>
                            <p>При неуспех в откриването на необходимата информация, може да се сържете със служител на Амарант по следните начини:</p>
                            <ul>
                                <li>Позвъняване на телефон: 08******** за решение на момента</li>
                                <li>Имейл до ХХХХХ@fidweb.net за отговор до 24 часа</li>
                                <li>Да отидете до офис на Амарант за разговор на живо - локации</li>
                            </ul>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[1] === 0 ? 'open' : 'close'} onClick={() => this.toggle(1)}>
                        <div>Кои са Амарант България?</div>
                    </div>
                    <AnimateHeight height={this.state.height[1]} >
                        <div>
                            <p>"АМАРАНТ България" ООД е основан през 2006г., като застрахователен посредник с лиценз № 7-3Б към Комисията по финансов надзор. Към момента, работим с всички лицензирани застрахователни компании и предлагаме пълна гама застрахователни продукти във връзка със застраховане на движимо, недвижимо имущество и други специфични рискове и отговорности.</p>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[2] === 0 ? 'open' : 'close'} onClick={() => this.toggle(2)}>
                        <div>Как мога да се свържа със служител на Амарант?</div>
                    </div>
                    <AnimateHeight height={this.state.height[2]} >
                        <div>
                            <p>Може да се сържете със служител на Амарант по следните начини:</p>
                            <ul>
                                <li>Позвъняване на телефон: 08******** за решение на момента</li>
                                <li>Имейл до ХХХХХ@fidweb.net за отговор до 24 часа</li>
                                <li>Да отидете до офис на Амарант за разговор на живо - локации</li>
                            </ul>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[3] === 0 ? 'open' : 'close'} onClick={() => this.toggle(3)}>
                        <div>Как мога да се запозная по-надълбоко със застрахователните процеси?</div>
                    </div>
                    <AnimateHeight height={this.state.height[3]} >
                        <div>
                            <p>При необходимост от допълнителна информация относно различните застраховки, посетете нашия сайт и менюто <Link to='/insurances'>„Застраховки“</Link> в него.</p>
                        </div>
                    </AnimateHeight>
                </div>

            </div>
        )
    }

}

export default connect()(FaqsListInfo7)