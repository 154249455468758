import React, { Component } from 'react'
import { connect } from 'react-redux'
import AnimateHeight from 'react-animate-height';

class FaqsListInfo2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: {
                0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0,
            },

        }
    }

    toggle = (index) => {
        const { height } = this.state;
        height[index] = height[index] === 0 ? 'auto' : 0
        this.setState({
            height: height
        });
    };

    render() {
        // const { height } = this.state;

        return (
            <div className='faqs-list-info'>

                <div className='faq-info'>
                    <div className={this.state.height[0] === 0 ? 'open' : 'close'} onClick={() => this.toggle(0)}>
                        <div>Всеки ли може да си инсталира мобилното проложение?</div>
                    </div>
                    <AnimateHeight height={this.state.height[0]} >
                        <div>
                            <p>Не е нужно предварително да сте клиент на Амарант, за да можете да използвате безплатните услуги от мобилното приложение. Всеки човек със смартфон, поддържащ операционна система IOS или Android, може да се възползва от възможностите, които предоставя първият дигитален брокер в България – Амарант.</p>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[1] === 0 ? 'open' : 'close'} onClick={() => this.toggle(1)}>
                        <div>Сгреших си телефонния номер, как да процедирам сега?</div>
                    </div>
                    <AnimateHeight height={this.state.height[1]} >
                        <div>
                            <p>Сгрешаването на номера няма да Ви позволи продължаване напред в приложението и ще трябва да въведете правилния телефонен номер, за да получите уникалния 4-цифрен код чрез СМС, неоходим за следващата стъпка.</p>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[2] === 0 ? 'open' : 'close'} onClick={() => this.toggle(2)}>
                        <div>Натискам бутона „ Създай профил“, но ми излиза грешка. Как мога да си направя регистрация?</div>
                    </div>
                    <AnimateHeight height={this.state.height[2]} >
                        <div>
                            <p>Преди натискането на бутона, трябва да сте абсолютно сигурни, че сте се запознали с:</p>
                            <ul className='ul'>
                                <li>Политиката за поверителност</li>
                                <li>Условията за използване</li>
                                <li>Допълнителната информация</li>
                            </ul>
                            <p>и отбелязали тикчето, което се намира вляво от тях, за да продължите напред в приложението.</p>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[3] === 0 ? 'open' : 'close'} onClick={() => this.toggle(3)}>
                        <div>Не ми връща четирицифрен код под формата на СМС. Как да си направя регистрацията?</div>
                    </div>
                    <AnimateHeight height={this.state.height[3]} >
                        <div>
                            <p>При възникване на следната ситуация, може да получите кода под формата на телефонно обаждане при натискане на бутона:<br />снимка</p>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[4] === 0 ? 'open' : 'close'} onClick={() => this.toggle(4)}>
                        <div>Не получавам СМС и не ми позволява да позвъня за получаване на 4-цифрен код. Как да си направя регистрацията?</div>
                    </div>
                    <AnimateHeight height={this.state.height[4]} >
                        <div>
                            <p>При възникване на следната ситуация, Ви препоръчваме да се свържете с:</p>
                            <ul className='ul'>
                                <li>Наш посредник на телефон: 08******** за решение на момента</li>
                                <li>Да опишете Вашия проблем и изпратите на: ХХХХХ@fidweb.net за отговор до 24 часа</li>
                            </ul>
                        </div>
                    </AnimateHeight>
                </div>

            </div>
        )
    }

}

export default connect()(FaqsListInfo2)