import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import AnimateHeight from 'react-animate-height';

class MedicalIns extends React.Component {
    state = {
        height: {
            0: 0, 1: 0
        },
    }

    toggle = (index) => {
        const { height } = this.state;
        height[index] = height[index] === 0 ? 'auto' : 0
        this.setState({
            height: height,
        });
    };

    render() {

        return (
            <div className='tab-info' id='medicalInsInfo'>

                <h4><span className='icon'><img src='/icons/home.svg' alt='' /></span>Медицинска застраховка</h4>
                <h5>За пътуване в чужбина</h5>
                <p>Осигурява квалифицирана медицинска помощ и транспортиране на застрахования, ако той е претърпял някаква злополука в чужда държава. Тази медицинска застраховка покрива и случаите, в които застрахованият се разболее на територията на друга страна.</p>
                <h5>За чужденци в България</h5>
                <p>Този вид застраховка е предназначена за чужди граждани или лица без гражданство, които пребивават краткосрочно в България или преминават транзит през територията на Република България, и се нуждаят от медицинска помощ. Услугата покрива медицинските разноски при злополука или акутно заболяване (което включва и зъболечение), репатриране и смърт вследствие на злополука.</p>
                <div className="open-close">
                    <div className={this.state.height[0] === 0 ? 'close' : 'open'} onClick={() => this.toggle(0)}>
                        <div><span className='icon'><img src='/icons/shield.svg' alt='' /></span>Какво е необходимо за добавяне в приложението? </div>
                    </div>
                    <AnimateHeight className='open-close-info' height={this.state.height[0]}>
                        <div>
                            <p>Единственият документ, от който имате нужда, за да добавите съществуваща застраховка в приложението, за да можете да я управлявате и да следите нейните срокове е номерътт на застраховката и да посочите с коя застрахователна компания е сключена</p>
                            <p><Link className='color-2' to='/download-app'>Свали приложението</Link></p>
                        </div>
                    </AnimateHeight>
                </div>

                <h4><span className='icon'><img src='/icons/home.svg' alt='' /></span>Туристическа застраховка</h4>
                <p>Туристическа застраховка покрива всякакви медицински разходи, които са вследствие на злополука или акутно заболяване, настъпили по време на вашия отдих и туризъм. Покрива и възстановява всички реално извършени разходи за оказване на първа помощ при внезапно заболяване или някаква злополука, както и ако ви се наложи хоспитализация или спешна стоматологична помощ. При наличие на туристическа застраховка, застрахователят възстановява разходите за цялата процедура по издирване, спасяване и транспортиране до най-близкото лечебно заведение в рамките на страната</p>
                <div className="open-close">
                    <div className={this.state.height[1] === 0 ? 'close' : 'open'} onClick={() => this.toggle(1)}>
                        <div><span className='icon'><img src='/icons/shield.svg' alt='' /></span>Какво е необходимо за добавяне в приложението? </div>
                    </div>
                    <AnimateHeight className='open-close-info' height={this.state.height[1]}>
                        <div>
                            <p>Единственият документ, от който имате нужда, за да добавите съществуваща застраховка в приложението, за да можете да я управлявате и да следите нейните срокове е номерътт на застраховката и да посочите с коя застрахователна компания е сключена</p>
                            <p><Link className='color-2' to='/download-app'>Свали приложението</Link></p>
                        </div>
                    </AnimateHeight>
                </div>

            </div>
        )
    }
}
export default connect()(MedicalIns)