import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withGetScreen } from 'react-getscreen'
import { BenefitVideo3 } from '../SectionBenefits'

class BenefitInfo3 extends Component {
    render() {
        return (
            <div className='benefit-info'>
                <p>Добавете вашите съществуващи полици в приложението, за да плащате онлайн вноските и не пропускате сроковете.</p>
                <ul className='ul'>
                    <li>Работи за всички водещи застрахователи в България</li>
                    <li>Работи за всички видове застраховки в България</li>
                    <li>Чат ботът ще ви преведе през процеса разбираемо и бързо</li>
                </ul>
                {this.props.isMobile() ? <div><BenefitVideo3 /></div> : ""}
            </div>
        )
    }
}

const options = { mobileLimit: 1024, shouldListenOnResize: true }
export default connect()(withGetScreen(BenefitInfo3, options))
