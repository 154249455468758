import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import AnimateHeight from 'react-animate-height';

class CarIns extends React.Component {
  state = {
    height: {
      0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0
    },
  }

  toggle = (index) => {
    const { height } = this.state;
    height[index] = height[index] === 0 ? 'auto' : 0
    this.setState({
      height: height,
    });
  };

  render() {

    return (
      <div className='tab-info' id='carInsInfo'>
        <h4><span className='icon'><img src='/icons/civil.svg' alt='' /></span>Гражданска отговорност</h4>
        <p>Това е отговорността към 3-ти лица в случай на ПТП. Застрахователната компания, в която физическо/юридическо лице е сключило своята ГО (задължителна за всички притежатели на МПС, по законите на Република България), е ангажирана да обезщети 3ти лица, които са претърпели имуществени и неимуществени вреди, в резултат на управлението на даденото МПС.</p>
        <div className="open-close">
          <div className={this.state.height[0] === 0 ? 'close' : 'open'} onClick={() => this.toggle(0)}>
            <div><span className='icon'><img src='/icons/buy.svg' alt='' /></span>Какво е необходимо за закупуване през приложението?</div>
          </div>
          <AnimateHeight className='open-close-info' height={this.state.height[0]}>
            <div>
              <p>Единственото нещо, от което имате нужда, за да закупите ГО през нашето приложение е малкият талон на автомобила, който искате да застраховате.</p>
              <p><Link className='color-2' to='/download-app'>Свали приложението</Link></p>
            </div>
          </AnimateHeight>
        </div>
        <div className="open-close">
          <div className={this.state.height[1] === 0 ? 'close' : 'open'} onClick={() => this.toggle(1)}>
            <div><span className='icon'><img src='/icons/shield.svg' alt='' /></span>Какво е необходимо за добавяне в приложението? </div>
          </div>
          <AnimateHeight className='open-close-info' height={this.state.height[1]}>
            <div>
              <p>Единственият документ, от който имате нужда, за да добавите съществуваща застраховка в приложението, за да можете да я управлявате и да следите нейните срокове е номерътт на застраховката и да посочите с коя застрахователна компания е сключена</p>
              <p><Link className='color-2' to='/download-app'>Свали приложението</Link></p>
            </div>
          </AnimateHeight>
        </div>

        <h4><span className='icon'><img src='/icons/motorhull.svg' alt='' /></span>Автокаско</h4>
        <p>Каско е особен вид имуществена застраховка, предназначена за покритие на специфични рискове, свързани с Вашите моторни превозни средства. Тя е доброволна и осигурява покритие срещу пожар, природни бедствия, кражба на автомобила или монтирано в него оборудване, авария вследствие сблъскване с друг автомобил или предмет на пътя и др.</p>
        <div className="open-close">
          <div className={this.state.height[2] === 0 ? 'close' : 'open'} onClick={() => this.toggle(2)}>
            <div><span className='icon'><img src='/icons/buy.svg' alt='' /></span>Какво е необходимо за закупуване през приложението?</div>
          </div>
          <AnimateHeight className='open-close-info' height={this.state.height[2]}>
            <div>
              <p>Единственото нещо, от което имате нужда, за да закупите ГО през нашето приложение е малкият талон на автомобила, който искате да застраховате.</p>
              <p><Link className='color-2' to='/download-app'>Свали приложението</Link></p>
            </div>
          </AnimateHeight>
        </div>
        <div className="open-close">
          <div className={this.state.height[3] === 0 ? 'close' : 'open'} onClick={() => this.toggle(3)}>
            <div><span className='icon'><img src='/icons/shield.svg' alt='' /></span>Какво е необходимо за добавяне в приложението? </div>
          </div>
          <AnimateHeight className='open-close-info' height={this.state.height[3]}>
            <div>
              <p>Единственият документ, от който имате нужда, за да добавите съществуваща застраховка в приложението, за да можете да я управлявате и да следите нейните срокове е номерътт на застраховката и да посочите с коя застрахователна компания е сключена</p>
              <p><Link className='color-2' to='/download-app'>Свали приложението</Link></p>
            </div>
          </AnimateHeight>
        </div>

        <h4><span className='icon'><img src='/icons/assistance.svg' alt='' /></span>Автоасистанс</h4>
        <p>При сключване на Автоаистанс Вие се сдобивате с лична денонощна Пътна помощ и медицинска подкрепа, в случай на необходимост, които ще се отзоват винаги, когато имате нужда от тях. Така ще сте напълно сигурни, че можете да разчитате по всяко време и при всяка ситуация на вашия Автоаистанс, само трябва да вдигнете телефона.</p>
        <div className="open-close">
          <div className={this.state.height[4] === 0 ? 'close' : 'open'} onClick={() => this.toggle(4)}>
            <div><span className='icon'><img src='/icons/shield.svg' alt='' /></span>Какво е необходимо за добавяне в приложението? </div>
          </div>
          <AnimateHeight className='open-close-info' height={this.state.height[4]}>
            <div>
              <p>Единственият документ, от който имате нужда, за да добавите съществуваща застраховка в приложението, за да можете да я управлявате и да следите нейните срокове е номерътт на застраховката и да посочите с коя застрахователна компания е сключена</p>
              <p><Link className='color-2' to='/download-app'>Свали приложението</Link></p>
            </div>
          </AnimateHeight>
        </div>
      </div>
    )
  }
}
export default connect()(CarIns)