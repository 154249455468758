import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withGetScreen } from 'react-getscreen'
import { BenefitVideo4 } from '../SectionBenefits'

class BenefitInfo4 extends Component {
    render() {
        return (
            <div className='benefit-info'>
                {/* <p>Добавете вашите съществуващи полици в приложението, за да не изпускате сроковете по подновяването им</p> */}
                <ul className='ul'>
                <li>Глоби към КАТ с възможност за директното им погасяване</li>
                    <li>Справка за наличие на винетка с възможност за закупуване на нова.</li>
                    <li>Запазване на час за Годишен Технически Преглед около Вас.</li>
                    <li>А в най-скоро време и заплащане на синия и зелена зона в София.</li>
                    <p>Всичко това в АМАРАНТ!</p>
                </ul>
                { this.props.isMobile() ? <div><BenefitVideo4/></div> : "" }
            </div>
        )
    }
}

const options = { mobileLimit: 1024, shouldListenOnResize: true  }
export default connect()(withGetScreen(BenefitInfo4, options))
