import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import AnimateHeight from 'react-animate-height';

class TransportIns extends React.Component {
    state = {
        height: {
            0: 0
        },
    }

    toggle = (index) => {
        const { height } = this.state;
        height[index] = height[index] === 0 ? 'auto' : 0
        this.setState({
            height: height,
        });
    };

    render() {

        return (
            <div className='tab-info' id='transportInsInfo'>
                <h4><span className='icon'><img src='/icons/truck.svg' alt='' /></span>Транспортни застраховки</h4>
                <p>Транспортните застраховки покриват вашите рискове, свързани с превозните средства, транспортиращи различни товари. Отнасят се спрямо цялостната или частичната липса или повреда на стока или имуществени и неимуществени вреди, причинени на трети лица по повод професионалната дейност на транспортните фирми</p>
                <div className="open-close">
                    <div className={this.state.height[0] === 0 ? 'close' : 'open'} onClick={() => this.toggle(0)}>
                        <div><span className='icon'><img src='/icons/shield.svg' alt='' /></span>Какво е необходимо за добавяне в приложението?</div>
                    </div>
                    <AnimateHeight className='open-close-info' height={this.state.height[0]}>
                        <div>
                            <p>Единственият документ, от който имате нужда, за да добавите съществуваща застраховка в приложението, за да можете да я управлявате и да следите нейните срокове е номерътт на застраховката и да посочите с коя застрахователна компания е сключена</p>
                            <p><Link className='color-2' to='/download-app'>Свали приложението</Link></p>
                        </div>
                    </AnimateHeight>
                </div>
            </div>
        )
    }
}
export default connect()(TransportIns)