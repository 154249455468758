import React, { Component } from 'react';
import './styles.scss';

class BlogBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: '',
      categoryColor: '',
      date: '',
      title: '',
      annotation: '',
      src: '',
      link: '/blog/details',
      styleName: ''
    }
  }

  render() {
    const { category, categoryColor, date, title, annotation, src, link, styleName } = this.props
    return (
      <div className={styleName}>
        <div className='blog-box'>
          <div className='blog-img'><a href={link}><img src={src} alt='' /></a></div>
          <div className='blog-info'>
            <div className='category'><a className={categoryColor} href='/blog/category'>{category}</a></div>
            <div className='date'>{date}</div>
            <h3 className='title'>{title}</h3>
            <p className='annotation'>{annotation}</p>
            <div className='link'><a href={link}>Прочети повече</a></div>
          </div>
        </div>
      </div>
    );
  }
}

export default BlogBox;