import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BlogBox } from '../../components'
import './styles.scss';

class Blog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      blogList: [
        { category: 'Новина', categoryColor: 'blue', date: '09.04.2020г.', title: 'Очаквайте скоро! Нова версия на нашето приложение', annotation: 'С удоволствие споделяме, че за втора поредна година АМАРАНТ бе удостоен с най-престижната награда в застрахователния сектор Застрахователен брокер на годината - Най-динамично развиващо се дружество” за 2019 г.', src: '/blog/blog5.jpg',  link:'/blog/details', styleName: '' },
        { category: 'Събитие', categoryColor: 'green', date: '22.03.2020г.', title: 'Откриване нов офис в  гр.Пловдив', annotation: 'С удоволствие споделяме, че за втора поредна година АМАРАНТ бе удостоен с най-престижната награда в', src: '/blog/blog2.jpg',  link:'/blog/details', styleName: '' },
        { category: 'Събитие', categoryColor: 'green', date: '01.01.2020г.', title: 'Амарант през 2020-та година', annotation: 'С удоволствие споделяме, че за втора поредна година АМАРАНТ бе удостоен с най-престижната награда в…', src: '/blog/blog6.jpg',  link:'/blog/details', styleName: '' },
        { category: 'Новина', categoryColor: 'blue', date: '09.04.2020г.', title: 'Очаквайте скоро! Нова версия на нашето приложение', annotation: 'С удоволствие споделяме, че за втора поредна година АМАРАНТ бе удостоен с най- престижната награда в застрахователния сектор “Застрахователен брокер на годината - Най-динамично развиващо се дружество” за 2019 г.', src: '/blog/blog5.jpg',  link:'/blog/details', styleName: '' },
        { category: 'Събитие', categoryColor: 'green', date: '22.03.2020г.', title: 'Откриване нов офис в  гр.Пловдив', annotation: 'С удоволствие споделяме, че за втора поредна година АМАРАНТ бе удостоен с най-престижната награда в', src: '/blog/blog2.jpg',  link:'/blog/details', styleName: '' },
        { category: 'Събитие', categoryColor: 'green', date: '01.01.2020г.', title: 'Амарант през 2020-та година', annotation: 'С удоволствие споделяме, че за втора поредна година АМАРАНТ бе удостоен с най-престижната награда в…', src: '/blog/blog6.jpg',  link:'/blog/details', styleName: '' },
      ],
      blogListArr: [],
    }
  }

  componentDidMount() {
    this.setState({
      blogListArr: this.state.blogList
    })
  }

  render() {

    const blogListHTML = this.state.blogListArr.map((blogListArr, index) =>
      <div key={index} className='col' id={index}>
        <BlogBox
          category={blogListArr.category}
          categoryColor={blogListArr.categoryColor}
          date={blogListArr.date}
          title={blogListArr.title}
          annotation={blogListArr.annotation}
          src={blogListArr.src}
          link={blogListArr.link}
        />
      </div>)

    return (
      <div className="blog">

        <div className="section section-main">
          <div className='container'>
            <div className='row'>
              <div className='col col-1'>
                <BlogBox
                  category='Новина'
                  categoryColor='blue'
                  date='22.06.2020г.'
                  title='Застрахователен брокер на 2019-та година'
                  annotation='С удоволствие споделяме, че за втора поредна година АМАРАНТ бе удостоен с най-престижната награда в застрахователния сектор “Застрахователен брокер на годината - Най-динамично развиващо се дружество” за 2019 г.'
                  src='/blog/blog1.jpg'
                  link='/blog/details'
                  styleName='news-onfocus'
                />
              </div>
              <div className='col col-2'>
                <div className='news-list'>
                  <BlogBox
                    category='Събитие'
                    categoryColor='green'
                    date='10.06.2020г.'
                    title='Откриване нов офис в  гр.Варна -   Център на 19.08.2020г.'
                    annotation='Откриваме нов офис в гр.Варна - Център. Това ще бъде нашият 232 офис и с удоволствие ви каним на откриването'
                    src='/blog/blog2.jpg'
                    link='/blog/details'
                    styleName='news-item'
                  />
                  <BlogBox
                    category='Нашият екип'
                    categoryColor='lightblue'
                    date='24.05.2020г.'
                    title='Семейството на Амарант расте'
                    annotation='В Петък нашата колежка Katerina Mracenkova даде живот 
                  на нов човек с име Йоанна!'
                    src='/blog/blog3.jpg'
                    link='/blog/details'
                    styleName='news-item'
                  />
                  <BlogBox
                    category='Новина'
                    categoryColor='blue'
                    date='11.04.2020г.'
                    title='Нов рекорд! Вече имаме над 260 000 клиента!'
                    annotation='Откриваме нов офис в гр.Варна - Център. Това ще бъде нашият 232 офис и с удоволствие ви каним на откриването'
                    src='/blog/blog4.jpg'
                    link='/blog/details'
                    styleName='news-item'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section section-bloglist">
          <div className='container'>
            <div className='row row-headline'>
              <div className='col'>
                <h2 className='uppercase'>Още от нас</h2>
              </div>
            </div>

            <div className='row'>
              {blogListHTML}
            </div>

            <div className='paging'>
              <div><button className='prev'><img src='/icons/prev.svg' alt='' /></button></div>
              <div><button>1</button></div>
              <div><button className='active'>2</button></div>
              <div><button>3</button></div>
              <div><button>4</button></div>
              <div><button className='next'><img src='/icons/next.svg' alt='' /></button></div>
            </div>

          </div>
        </div>

      </div>
    )
  }

}

export default connect()(Blog)