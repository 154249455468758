import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { NavMain } from '../NavMain'
import './styles.scss'

class Header extends Component {
    render() {
        return (
            <header className="header">
                <div className='container'>
                    <div className='row'>
                        <div className='col col-logo'>
                            <NavLink to='/' className='logo'><img src='/amarant-logo.svg' alt='Амарант България' /></NavLink>
                        </div>
                        <div className='col col-headline'>
                            <h2>Свали приложението</h2>
                        </div>
                        <div className='col col-nav-main'>
                            <NavMain />
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

export default connect()(Header)
