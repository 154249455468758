import React, { Component } from 'react';
import './styles.scss';

class QR extends Component {
	constructor(props) {
		super(props);
		this.state = {
			styleName: ''
		}
	}

	render() {
		const { styleName } = this.props

		return (
			<div className={'qr ' + styleName}>
				<svg xmlns="http://www.w3.org/2000/svg" width="101.2" height="101.2" viewBox="0 0 101.2 101.2">
					<g>
						<path d="M39.8,3.8c0.5,0,0.9-0.4,0.9-0.9l0,0v0.9H39.8z" />
						<path d="M44.7,3V0.7c0-0.5-0.4-0.9-0.9-0.9l0,0h-2.3c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v3.2h4V3H44.7z" />
						<path d="M61.6-0.2h2.7c0.4,0,0.7,0.3,0.7,0.7v2.7c0,0.4-0.3,0.7-0.7,0.7h-2.7c-0.4,0-0.7-0.3-0.7-0.7V0.5
		C60.9,0.1,61.2-0.2,61.6-0.2z"/>
						<path d="M33.5,3.8c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v3.2h4v-4h-3.1V3.8z" />
						<rect x="36.6" y="3.8" width="4" height="4" />
						<path d="M44.7,7c0,0.5-0.4,0.9-0.9,0.9l0,0h-3.2v-4h4V7H44.7z" />
						<path d="M56,3.8h-2.3c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0V7c0,0.5,0.4,0.9,0.9,0.9l0,0h3.2v-4L56,3.8L56,3.8z" />
						<path d="M57.7,7.9h2.4C60.6,7.9,61,7.5,61,7l0,0V4.7c0-0.5-0.4-0.9-0.9-0.9l0,0h-3.2v4L57.7,7.9L57.7,7.9z" />
						<path d="M64.1,7.9C64.6,7.9,65,7.5,65,7l0,0v0.9H64.1z" />
						<path d="M69,7V4.7c0-0.5-0.4-0.9-0.9-0.9l0,0h-2.3c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v3.2h4V7H69z" />
						<path d="M32.6,11.1c0,0.5,0.4,0.9,0.9,0.9l0,0h2.3c0.5,0,0.9-0.4,0.9-0.9l0,0V7.9h-4v3.2H32.6z" />
						<path d="M37.5,7.9h-0.9v0.9C36.6,8.3,37,7.9,37.5,7.9L37.5,7.9z" />
						<path d="M61.8,7.9c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0V12h4V8h-3.1V7.9z" />
						<rect x="65" y="7.9" width="4" height="4" />
						<path d="M35.8,16c0.5,0,0.9-0.4,0.9-0.9l0,0V16H35.8z" />
						<path d="M40.7,15.1v-2.3c0-0.5-0.4-0.9-0.9-0.9l0,0h-2.3c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0V16h4v-0.9H40.7z" />
						<path d="M41.5,16c-0.5,0-0.9-0.4-0.9-0.9l0,0V16H41.5z" />
						<path d="M52.8,15.1v-2.3c0-0.5-0.4-0.9-0.9-0.9l0,0h-2.3c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0V16h4v-0.9H52.8z" />
						<path d="M53.7,16c-0.5,0-0.9-0.4-0.9-0.9l0,0V16H53.7z" />
						<path d="M60.9,15.1c0,0.5,0.4,0.9,0.9,0.9l0,0H65v-4h-4L60.9,15.1L60.9,15.1z" />
						<rect x="65" y="11.9" width="4" height="4" />
						<path d="M33.5,16c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v3.2h4v-4h-3.1V16z" />
						<rect x="36.6" y="16" width="4" height="4" />
						<path d="M43.9,16c0.5,0,0.9,0.4,0.9,0.9l0,0v3.2h-4v-4h3.1V16z" />
						<rect x="48.8" y="16" width="4" height="4" />
						<rect x="52.8" y="16" width="4" height="4" />
						<path d="M57.7,20h2.4c0.5,0,0.9-0.4,0.9-0.9l0,0v-2.3c0-0.5-0.4-0.9-0.9-0.9l0,0h-3.2v4L57.7,20L57.7,20z" />
						<path d="M65,16.8v-0.9h-0.9C64.6,16,65,16.4,65,16.8L65,16.8z" />
						<path d="M65,19.2c0,0.5,0.4,0.9,0.9,0.9l0,0h2.2c0.5,0,0.9-0.4,0.9-0.9l0,0V16h-4V19.2z" />
						<rect x="32.6" y="20" width="4" height="4" />
						<path d="M37.5,20h-0.9v0.9C36.6,20.4,37,20,37.5,20L37.5,20z" />
						<path d="M40.7,20.9V20h-0.9C40.3,20,40.7,20.4,40.7,20.9L40.7,20.9z" />
						<rect x="40.7" y="20" width="4" height="4" />
						<rect x="48.8" y="20" width="4" height="4" />
						<path d="M53.7,20h-0.9v0.9C52.8,20.4,53.2,20,53.7,20L53.7,20z" />
						<path d="M61.6,20h2.7c0.4,0,0.7,0.3,0.7,0.7v2.7c0,0.4-0.3,0.7-0.7,0.7h-2.7c-0.4,0-0.7-0.3-0.7-0.7v-2.7
		C60.9,20.3,61.2,20,61.6,20z"/>
						<rect x="32.6" y="24.1" width="4" height="4" />
						<rect x="40.7" y="24.1" width="4" height="4" />
						<path d="M45.6,28.1c-0.5,0-0.9-0.4-0.9-0.9l0,0v0.9H45.6z" />
						<path d="M48.8,27.3c0,0.5,0.4,0.9,0.9,0.9l0,0H52c0.5,0,0.9-0.4,0.9-0.9l0,0v-3.2h-4v3.2H48.8z" />
						<path d="M57.5,24.1h2.7c0.4,0,0.7,0.3,0.7,0.7v2.7c0,0.4-0.3,0.7-0.7,0.7h-2.7c-0.4,0-0.7-0.3-0.7-0.7v-2.7
		C56.9,24.4,57.2,24.1,57.5,24.1z"/>
						<path d="M65.6,24.1h2.7c0.4,0,0.7,0.3,0.7,0.7v2.7c0,0.4-0.3,0.7-0.7,0.7h-2.7c-0.4,0-0.7-0.3-0.7-0.7v-2.7
		C65,24.4,65.3,24.1,65.6,24.1z"/>
						<path d="M32.6,31.3c0,0.5,0.4,0.9,0.9,0.9l0,0h2.3c0.5,0,0.9-0.4,0.9-0.9l0,0v-3.2h-4v3.2H32.6z" />
						<path d="M40.7,31.3c0,0.5,0.4,0.9,0.9,0.9l0,0h3.2v-4h-4v3.1H40.7z" />
						<path d="M47.9,28.1c0.5,0,0.9,0.4,0.9,0.9l0,0v3.2h-4v-4h3.1V28.1z" />
						<path d="M49.6,32.2c-0.5,0-0.9-0.4-0.9-0.9l0,0v0.9H49.6z" />
						<path d="M1.1,32.2c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v3.2h4v-4H1.1V32.2z" />
						<path d="M7.5,32.2c0.5,0,0.9,0.4,0.9,0.9l0,0v3.2h-4v-4h3.1V32.2z" />
						<path d="M13.1,32.2h2.7c0.4,0,0.7,0.3,0.7,0.7v2.7c0,0.4-0.3,0.7-0.7,0.7h-2.7c-0.4,0-0.7-0.3-0.7-0.7v-2.7
		C12.4,32.5,12.7,32.2,13.1,32.2z"/>
						<path d="M27.7,32.2h-2.3c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v2.3c0,0.5,0.4,0.9,0.9,0.9l0,0h3.2v-4h-0.9V32.2z" />
						<path d="M31.7,32.2c0.5,0,0.9,0.4,0.9,0.9l0,0v3.2h-4v-4h3.1V32.2z" />
						<path d="M33.5,36.2c-0.5,0-0.9-0.4-0.9-0.9l0,0v0.9H33.5z" />
						<path d="M44.7,33v-0.9h-0.9C44.3,32.2,44.7,32.5,44.7,33L44.7,33z" />
						<rect x="44.7" y="32.2" width="4" height="4" />
						<rect x="48.8" y="32.2" width="4" height="4" />
						<path d="M56,32.2c0.5,0,0.9,0.4,0.9,0.9l0,0v3.2h-4v-4H56V32.2z" />
						<path d="M64.1,36.2c0.5,0,0.9-0.4,0.9-0.9l0,0v0.9H64.1z" />
						<path d="M69,35.3V33c0-0.5-0.4-0.9-0.9-0.9l0,0h-2.3c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v3.2h4v-0.9H69z" />
						<path d="M73.9,32.2c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v3.2h4v-4h-3.1V32.2z" />
						<rect x="77.1" y="32.2" width="4" height="4" />
						<path d="M84.3,32.2c0.5,0,0.9,0.4,0.9,0.9l0,0v3.2h-4v-4h3.1V32.2z" />
						<path d="M92.4,32.2h-2.3c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v2.3c0,0.5,0.4,0.9,0.9,0.9l0,0h3.2v-4h-0.9V32.2z" />
						<path d="M96.5,32.2c0.5,0,0.9,0.4,0.9,0.9l0,0v3.2h-4v-4h3.1V32.2z" />
						<path d="M0.2,39.4c0,0.5,0.4,0.9,0.9,0.9l0,0h3.2v-4h-4v3.1H0.2z" />
						<path d="M8.3,39.4c0,0.5-0.4,0.9-0.9,0.9l0,0H4.2v-4h4L8.3,39.4z" />
						<path d="M21.1,36.2h2.7c0.4,0,0.7,0.3,0.7,0.7v2.7c0,0.4-0.3,0.7-0.7,0.7h-2.7c-0.4,0-0.7-0.3-0.7-0.7v-2.7
		C20.5,36.5,20.8,36.2,21.1,36.2z"/>
						<path d="M28.6,37.1v-0.9h-0.9C28.2,36.2,28.6,36.6,28.6,37.1L28.6,37.1z" />
						<path d="M27.7,40.2c0.5,0,0.9-0.4,0.9-0.9l0,0v0.9H27.7z" />
						<rect x="28.6" y="36.2" width="4" height="4" />
						<rect x="32.6" y="36.2" width="4" height="4" />
						<path d="M37.5,40.2h2.3c0.5,0,0.9-0.4,0.9-0.9l0,0v-2.2c0-0.5-0.4-0.9-0.9-0.9l0,0h-3.2v4H37.5z" />
						<rect x="44.7" y="36.2" width="4" height="4" />
						<rect x="48.8" y="36.2" width="4" height="4" />
						<path d="M56.9,39.4c0,0.5-0.4,0.9-0.9,0.9l0,0h-3.2v-4h4L56.9,39.4L56.9,39.4z" />
						<path d="M64.1,36.2h-2.3c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v2.3c0,0.5,0.4,0.9,0.9,0.9l0,0H65v-4h-0.9V36.2z" />
						<path d="M69,39.4c0,0.5-0.4,0.9-0.9,0.9l0,0h-3.2v-4h4L69,39.4L69,39.4z" />
						<path d="M72.2,40.2c0.5,0,0.9-0.4,0.9-0.9l0,0v0.9H72.2z" />
						<rect x="73" y="36.2" width="4" height="4" />
						<path d="M78,36.2h-0.9v0.9C77.1,36.6,77.5,36.2,78,36.2C77.9,36.2,77.9,36.2,78,36.2L78,36.2z" />
						<path d="M81.1,37.1v-0.9h-0.9C80.7,36.2,81.1,36.6,81.1,37.1L81.1,37.1z" />
						<path d="M78,40.2c-0.5,0-0.9-0.4-0.9-0.9l0,0v0.9H78z" />
						<path d="M81.1,39.4c0,0.5,0.4,0.9,0.9,0.9l0,0h2.3c0.5,0,0.9-0.4,0.9-0.9l0,0v-3.2h-4v3.2H81.1z" />
						<path d="M93.3,37.1v-0.9h-0.9C92.9,36.2,93.3,36.6,93.3,37.1L93.3,37.1z" />
						<path d="M93.3,39.4c0,0.5,0.4,0.9,0.9,0.9l0,0h2.3c0.5,0,0.9-0.4,0.9-0.9l0,0v-3.2h-4v3.2H93.3z" />
						<path d="M9,40.2h2.7c0.4,0,0.7,0.3,0.7,0.7v2.7c0,0.4-0.3,0.7-0.7,0.7H9c-0.4,0-0.7-0.3-0.7-0.7v-2.7C8.3,40.6,8.6,40.2,9,40.2z" />
						<path d="M15.6,44.3c0.5,0,0.9-0.4,0.9-0.9l0,0v0.9H15.6z" />
						<path d="M20.5,43.4v-2.3c0-0.5-0.4-0.9-0.9-0.9l0,0h-2.3c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v3.2h4L20.5,43.4L20.5,43.4z" />
						<path d="M27.7,40.2h-2.3c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v2.3c0,0.5,0.4,0.9,0.9,0.9l0,0h3.2v-4h-0.9V40.2z" />
						<rect x="28.6" y="40.2" width="4" height="4" />
						<path d="M33.5,40.2h-0.9v0.9C32.6,40.6,33,40.2,33.5,40.2L33.5,40.2z" />
						<path d="M33.5,44.3c-0.5,0-0.9-0.4-0.9-0.9l0,0v0.9H33.5z" />
						<path d="M43.9,44.3c0.5,0,0.9-0.4,0.9-0.9l0,0v0.9H43.9z" />
						<rect x="44.7" y="40.2" width="4" height="4" />
						<rect x="48.8" y="40.2" width="4" height="4" />
						<path d="M53.7,40.2h-0.9v0.9C52.8,40.6,53.2,40.2,53.7,40.2L53.7,40.2z" />
						<path d="M53.7,44.3c-0.5,0-0.9-0.4-0.9-0.9l0,0v0.9H53.7z" />
						<path d="M68.1,44.3c0.5,0,0.9-0.4,0.9-0.9l0,0v0.9H68.1z" />
						<path d="M69.9,40.2c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v3.2h4v-4h-3.1V40.2z" />
						<rect x="73" y="40.2" width="4" height="4" />
						<path d="M80.3,40.2c0.5,0,0.9,0.4,0.9,0.9l0,0v3.2h-4v-4h3.1V40.2z" />
						<path d="M82,44.3c-0.5,0-0.9-0.4-0.9-0.9l0,0v0.9H82z" />
						<path d="M88.4,40.2h-2.3c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v2.3c0,0.5,0.4,0.9,0.9,0.9l0,0h3.2v-4h-0.9V40.2z" />
						<path d="M90.1,44.3h2.3c0.5,0,0.9-0.4,0.9-0.9l0,0v-2.3c0-0.5-0.4-0.9-0.9-0.9l0,0h-3.2v4h0.9V44.3z" />
						<path d="M1.1,44.3c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v3.2h4v-4H1.1V44.3z" />
						<path d="M5.1,48.3h2.3c0.5,0,0.9-0.4,0.9-0.9l0,0v-2.3c0-0.5-0.4-0.9-0.9-0.9l0,0H4.2v4L5.1,48.3z" />
						<path d="M13.2,44.3c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v3.2h4v-4h-3.1V44.3z" />
						<rect x="16.4" y="44.3" width="4" height="4" />
						<path d="M28.6,45.2v-0.9h-0.9C28.2,44.3,28.6,44.7,28.6,45.2C28.6,45.1,28.6,45.2,28.6,45.2z" />
						<path d="M28.6,47.5c0,0.5,0.4,0.9,0.9,0.9l0,0h3.2v-4h-4v3.1H28.6z" />
						<rect x="32.6" y="44.3" width="4" height="4" />
						<rect x="36.6" y="44.3" width="4" height="4" />
						<rect x="40.7" y="44.3" width="4" height="4" />
						<rect x="44.7" y="44.3" width="4" height="4" />
						<rect x="48.8" y="44.3" width="4" height="4" />
						<rect x="52.8" y="44.3" width="4" height="4" />
						<path d="M57.7,48.3h2.4c0.5,0,0.9-0.4,0.9-0.9l0,0v-2.3c0-0.5-0.4-0.9-0.9-0.9l0,0h-3.2v4L57.7,48.3z" />
						<path d="M65.8,44.3c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v3.2h4v-4h-3.1V44.3z" />
						<rect x="69" y="44.3" width="4" height="4" />
						<rect x="73" y="44.3" width="4" height="4" />
						<rect x="77.1" y="44.3" width="4" height="4" />
						<path d="M82,48.3h2.3c0.5,0,0.9-0.4,0.9-0.9l0,0v-2.3c0-0.5-0.4-0.9-0.9-0.9l0,0h-3.2v4L82,48.3z" />
						<path d="M96.5,48.3c0.5,0,0.9-0.4,0.9-0.9l0,0v0.9H96.5z" />
						<path d="M101.4,47.5v-2.3c0-0.5-0.4-0.9-0.9-0.9l0,0h-2.3c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v3.2h4v-0.9H101.4z" />
						<path d="M0.2,51.5c0,0.5,0.4,0.9,0.9,0.9l0,0h2.3c0.5,0,0.9-0.4,0.9-0.9l0,0v-3.2h-4L0.2,51.5L0.2,51.5z" />
						<path d="M5.1,48.3H4.2v0.9C4.3,48.7,4.7,48.3,5.1,48.3L5.1,48.3z" />
						<path d="M12.4,51.5c0,0.5,0.4,0.9,0.9,0.9l0,0h3.2v-4h-4v3.1H12.4z" />
						<path d="M20.5,51.5c0,0.5-0.4,0.9-0.9,0.9l0,0h-3.2v-4h4v3.1H20.5z" />
						<path d="M25.2,48.3h2.7c0.4,0,0.7,0.3,0.7,0.7v2.7c0,0.4-0.3,0.7-0.7,0.7h-2.7c-0.4,0-0.7-0.3-0.7-0.7V49
		C24.5,48.6,24.8,48.3,25.2,48.3z"/>
						<path d="M32.6,49.2v-0.9h-0.9C32.2,48.3,32.6,48.7,32.6,49.2L32.6,49.2z" />
						<path d="M31.7,52.4c0.5,0,0.9-0.4,0.9-0.9l0,0v0.9H31.7z" />
						<rect x="32.6" y="48.3" width="4" height="4" />
						<path d="M37.5,48.3h-0.9v0.9C36.6,48.7,37,48.3,37.5,48.3L37.5,48.3z" />
						<path d="M40.7,49.2v-0.9h-0.9C40.3,48.3,40.7,48.7,40.7,49.2L40.7,49.2z" />
						<path d="M40.7,51.5c0,0.5,0.4,0.9,0.9,0.9l0,0h3.2v-4h-4v3.1H40.7z" />
						<rect x="44.7" y="48.3" width="4" height="4" />
						<path d="M49.6,48.3h-0.9v0.9C48.8,48.7,49.2,48.3,49.6,48.3L49.6,48.3z" />
						<path d="M65,51.5c0,0.5,0.4,0.9,0.9,0.9l0,0h2.2c0.5,0,0.9-0.4,0.9-0.9l0,0v-3.2h-4V51.5z" />
						<path d="M69.9,48.3H69v0.9C69,48.7,69.4,48.3,69.9,48.3L69.9,48.3z" />
						<path d="M73,49.2v-0.9h-0.9C72.7,48.3,73,48.7,73,49.2L73,49.2z" />
						<path d="M73,51.5c0,0.5,0.4,0.9,0.9,0.9l0,0h3.2v-4h-4L73,51.5L73,51.5z" />
						<rect x="77.1" y="48.3" width="4" height="4" />
						<path d="M82,48.3h-0.9v0.9C81.1,48.7,81.5,48.3,82,48.3L82,48.3z" />
						<path d="M82,52.4c-0.5,0-0.9-0.4-0.9-0.9l0,0v0.9H82z" />
						<path d="M90.1,48.3c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v3.2h4v-4h-3.1V48.3z" />
						<rect x="93.3" y="48.3" width="4" height="4" />
						<rect x="97.3" y="48.3" width="4" height="4" />
						<path d="M7.5,52.4H5.1c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v2.3c0,0.5,0.4,0.9,0.9,0.9l0,0h3.2v-4H7.5V52.4z" />
						<path d="M11.5,52.4c0.5,0,0.9,0.4,0.9,0.9l0,0v3.2h-4v-4L11.5,52.4L11.5,52.4z" />
						<path d="M13.2,56.4c-0.5,0-0.9-0.4-0.9-0.9l0,0v0.9H13.2z" />
						<path d="M21.1,52.4h2.7c0.4,0,0.7,0.3,0.7,0.7v2.7c0,0.4-0.3,0.7-0.7,0.7h-2.7c-0.4,0-0.7-0.3-0.7-0.7v-2.7
		C20.5,52.7,20.8,52.4,21.1,52.4z"/>
						<path d="M27.7,56.4c0.5,0,0.9-0.4,0.9-0.9l0,0v0.9H27.7z" />
						<path d="M29.4,52.4c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v3.2h4v-4L29.4,52.4L29.4,52.4z" />
						<rect x="32.6" y="52.4" width="4" height="4" />
						<path d="M44.7,53.2v-0.9h-0.9C44.3,52.4,44.7,52.8,44.7,53.2L44.7,53.2z" />
						<rect x="44.7" y="52.4" width="4" height="4" />
						<path d="M60.9,55.6v-2.3c0-0.5-0.4-0.9-0.9-0.9l0,0h-2.3c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v3.2h4v-0.9H60.9z" />
						<path d="M61.8,56.4c-0.5,0-0.9-0.4-0.9-0.9l0,0v0.9H61.8z" />
						<path d="M77.1,53.2v-0.9h-0.9C76.7,52.4,77.1,52.8,77.1,53.2L77.1,53.2z" />
						<path d="M77.1,55.6c0,0.5,0.4,0.9,0.9,0.9l0,0h3.1v-4h-4V55.6z" />
						<path d="M84.3,52.4c0.5,0,0.9,0.4,0.9,0.9l0,0v3.2h-4v-4L84.3,52.4L84.3,52.4z" />
						<path d="M86,56.4c-0.5,0-0.9-0.4-0.9-0.9l0,0v0.9H86z" />
						<path d="M89.2,55.6c0,0.5,0.4,0.9,0.9,0.9l0,0h3.2v-4h-4L89.2,55.6L89.2,55.6z" />
						<rect x="93.3" y="52.4" width="4" height="4" />
						<rect x="97.3" y="52.4" width="4" height="4" />
						<path d="M0.9,56.4h2.7c0.4,0,0.7,0.3,0.7,0.7v2.7c0,0.4-0.3,0.7-0.7,0.7H0.9c-0.4,0-0.7-0.3-0.7-0.7v-2.7
		C0.2,56.7,0.5,56.4,0.9,56.4z"/>
						<path d="M8.3,57.3v-0.9H7.4C7.9,56.4,8.3,56.8,8.3,57.3L8.3,57.3z" />
						<path d="M8.3,59.6c0,0.5,0.4,0.9,0.9,0.9l0,0h3.2v-4h-4v3.1H8.3z" />
						<path d="M15.6,56.4c0.5,0,0.9,0.4,0.9,0.9l0,0v3.2h-4v-4L15.6,56.4L15.6,56.4z" />
						<path d="M17.3,60.5c-0.5,0-0.9-0.4-0.9-0.9l0,0v0.9H17.3z" />
						<path d="M27.7,56.4h-2.3c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v2.3c0,0.5,0.4,0.9,0.9,0.9l0,0h3.2v-4h-0.9V56.4z" />
						<rect x="28.6" y="56.4" width="4" height="4" />
						<rect x="32.6" y="56.4" width="4" height="4" />
						<path d="M37.5,60.5c-0.5,0-0.9-0.4-0.9-0.9l0,0v0.9H37.5z" />
						<path d="M43.9,60.5c0.5,0,0.9-0.4,0.9-0.9l0,0v0.9H43.9z" />
						<rect x="44.7" y="56.4" width="4" height="4" />
						<path d="M56.9,59.6c0,0.5,0.4,0.9,0.9,0.9l0,0H61v-4h-4L56.9,59.6L56.9,59.6z" />
						<rect x="60.9" y="56.4" width="4" height="4" />
						<path d="M65.8,60.5h2.3c0.5,0,0.9-0.4,0.9-0.9l0,0v-2.3c0-0.5-0.4-0.9-0.9-0.9l0,0h-3.2v4h0.9V60.5z" />
						<path d="M77.1,59.6v-2.3c0-0.5-0.4-0.9-0.9-0.9l0,0h-2.3c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v3.2h4v-0.9H77.1z" />
						<path d="M81.1,57.3v-0.9h-0.9C80.7,56.4,81.1,56.8,81.1,57.3L81.1,57.3z" />
						<path d="M78,60.5c-0.5,0-0.9-0.4-0.9-0.9l0,0v0.9H78z" />
						<path d="M81.1,59.6c0,0.5,0.4,0.9,0.9,0.9l0,0h3.2v-4h-4L81.1,59.6L81.1,59.6z" />
						<path d="M86,60.5h2.3c0.5,0,0.9-0.4,0.9-0.9l0,0v-2.3c0-0.5-0.4-0.9-0.9-0.9l0,0h-3.2v4H86V60.5z" />
						<path d="M97.3,57.3v-0.9h-0.9C96.9,56.4,97.3,56.8,97.3,57.3L97.3,57.3z" />
						<path d="M97.3,59.6c0,0.5,0.4,0.9,0.9,0.9l0,0h2.3c0.5,0,0.9-0.4,0.9-0.9l0,0v-3.2h-4v3.2H97.3z" />
						<path d="M3.4,64.5c0.5,0,0.9-0.4,0.9-0.9l0,0v0.9H3.4z" />
						<path d="M8.3,63.7v-2.3c0-0.5-0.4-0.9-0.9-0.9l0,0H5.1c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v3.2h4v-0.9H8.3z" />
						<path d="M12.4,61.3v-0.9h-0.9C12,60.5,12.4,60.9,12.4,61.3L12.4,61.3z" />
						<path d="M12.4,63.7c0,0.5,0.4,0.9,0.9,0.9l0,0h3.2v-4h-4v3.1H12.4z" />
						<rect x="16.4" y="60.5" width="4" height="4" />
						<path d="M23.6,60.5c0.5,0,0.9,0.4,0.9,0.9l0,0v3.2h-4v-4L23.6,60.5L23.6,60.5z" />
						<path d="M25.4,64.5c-0.5,0-0.9-0.4-0.9-0.9l0,0v0.9H25.4z" />
						<path d="M32.6,61.3v-0.9h-0.9C32.2,60.5,32.6,60.9,32.6,61.3L32.6,61.3z" />
						<rect x="32.6" y="60.5" width="4" height="4" />
						<rect x="36.6" y="60.5" width="4" height="4" />
						<rect x="40.7" y="60.5" width="4" height="4" />
						<path d="M48.8,63.7c0,0.5-0.4,0.9-0.9,0.9l0,0h-3.2v-4h4L48.8,63.7z" />
						<path d="M52,64.5c0.5,0,0.9-0.4,0.9-0.9l0,0v0.9H52z" />
						<path d="M56.9,63.7v-2.3c0-0.5-0.4-0.9-0.9-0.9l0,0h-2.3c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v3.2h4v-0.9H56.9z" />
						<path d="M72.2,64.5c0.5,0,0.9-0.4,0.9-0.9l0,0v0.9H72.2z" />
						<rect x="73" y="60.5" width="4" height="4" />
						<path d="M80.3,60.5c0.5,0,0.9,0.4,0.9,0.9l0,0v3.2h-4v-4L80.3,60.5L80.3,60.5z" />
						<path d="M82,64.5c-0.5,0-0.9-0.4-0.9-0.9l0,0v0.9H82z" />
						<path d="M3.4,64.5H1.1c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v2.3c0,0.5,0.4,0.9,0.9,0.9l0,0h3.2v-4H3.4V64.5z" />
						<path d="M8.3,67.7c0,0.5-0.4,0.9-0.9,0.9l0,0H4.2v-4h4L8.3,67.7z" />
						<path d="M16.4,65.4v-0.9h-0.9C16,64.5,16.4,64.9,16.4,65.4L16.4,65.4z" />
						<path d="M16.4,67.7c0,0.5,0.4,0.9,0.9,0.9l0,0h3.2v-4h-4L16.4,67.7L16.4,67.7z" />
						<rect x="20.5" y="64.5" width="4" height="4" />
						<path d="M25.4,68.6h2.3c0.5,0,0.9-0.4,0.9-0.9l0,0v-2.3c0-0.5-0.4-0.9-0.9-0.9l0,0h-3.2v4h0.9V68.6z" />
						<rect x="32.6" y="64.5" width="4" height="4" />
						<rect x="36.6" y="64.5" width="4" height="4" />
						<path d="M44.7,67.7c0,0.5-0.4,0.9-0.9,0.9l0,0h-3.2v-4h4v3.1H44.7z" />
						<path d="M45.6,64.5h-0.9v0.9C44.7,64.9,45.1,64.5,45.6,64.5L45.6,64.5z" />
						<path d="M49.6,64.5c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v3.2h4v-4L49.6,64.5L49.6,64.5z" />
						<rect x="52.8" y="64.5" width="4" height="4" />
						<path d="M65.8,64.5c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v3.2h4v-4L65.8,64.5L65.8,64.5z" />
						<rect x="69" y="64.5" width="4" height="4" />
						<rect x="73" y="64.5" width="4" height="4" />
						<rect x="77.1" y="64.5" width="4" height="4" />
						<path d="M84.3,64.5c0.5,0,0.9,0.4,0.9,0.9l0,0v3.2h-4v-4L84.3,64.5L84.3,64.5z" />
						<path d="M98,64.5h2.7c0.4,0,0.7,0.3,0.7,0.7v2.7c0,0.4-0.3,0.7-0.7,0.7H98c-0.4,0-0.7-0.3-0.7-0.7v-2.7
		C97.3,64.8,97.6,64.5,98,64.5z"/>
						<rect x="32.6" y="68.6" width="4" height="4" />
						<path d="M37.5,68.6h-0.9v0.9C36.6,68.9,37,68.6,37.5,68.6L37.5,68.6z" />
						<path d="M47.9,72.6c0.5,0,0.9-0.4,0.9-0.9l0,0v0.9H47.9z" />
						<rect x="48.8" y="68.6" width="4" height="4" />
						<rect x="52.8" y="68.6" width="4" height="4" />
						<path d="M64.1,72.6c0.5,0,0.9-0.4,0.9-0.9l0,0v0.9H64.1z" />
						<rect x="65" y="68.6" width="4" height="4" />
						<path d="M69.9,68.6H69v0.9C69,68.9,69.4,68.6,69.9,68.6L69.9,68.6z" />
						<path d="M81.1,69.4v-0.9h-0.9C80.7,68.6,81.1,68.9,81.1,69.4L81.1,69.4z" />
						<rect x="81.1" y="68.6" width="4" height="4" />
						<path d="M86,72.6c-0.5,0-0.9-0.4-0.9-0.9l0,0v0.9H86z" />
						<path d="M32.6,75.8c0,0.5,0.4,0.9,0.9,0.9l0,0h2.3c0.5,0,0.9-0.4,0.9-0.9l0,0v-3.2h-4v3.2H32.6z" />
						<path d="M47.9,72.6h-2.3c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v2.3c0,0.5,0.4,0.9,0.9,0.9l0,0h3.2v-4h-0.9V72.6z" />
						<rect x="48.8" y="72.6" width="4" height="4" />
						<rect x="52.8" y="72.6" width="4" height="4" />
						<path d="M57.7,76.6c-0.5,0-0.9-0.4-0.9-0.9l0,0v0.9H57.7z" />
						<path d="M64.1,72.6h-2.3c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v2.3c0,0.5,0.4,0.9,0.9,0.9l0,0H65v-4h-0.9V72.6z" />
						<rect x="65" y="72.6" width="4" height="4" />
						<path d="M73.7,72.6h2.7c0.4,0,0.7,0.3,0.7,0.7V76c0,0.4-0.3,0.7-0.7,0.7h-2.7c-0.4,0-0.7-0.3-0.7-0.7v-2.7
		C73,72.9,73.3,72.6,73.7,72.6z"/>
						<rect x="81.1" y="72.6" width="4" height="4" />
						<path d="M88.4,72.6c0.5,0,0.9,0.4,0.9,0.9l0,0v3.2h-4v-4L88.4,72.6L88.4,72.6z" />
						<path d="M92.4,76.6c0.5,0,0.9-0.4,0.9-0.9l0,0v0.9H92.4z" />
						<path d="M90.1,76.6c-0.5,0-0.9-0.4-0.9-0.9l0,0v0.9H90.1z" />
						<path d="M94.1,72.6c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v3.2h4v-4L94.1,72.6L94.1,72.6z" />
						<path d="M100.5,72.6c0.5,0,0.9,0.4,0.9,0.9l0,0v3.2h-4v-4L100.5,72.6L100.5,72.6z" />
						<path d="M39.8,76.6h-2.3c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v2.3c0,0.5,0.4,0.9,0.9,0.9l0,0h3.2v-4h-0.9V76.6z" />
						<path d="M43.9,76.6c0.5,0,0.9,0.4,0.9,0.9l0,0v3.2h-4v-4L43.9,76.6L43.9,76.6z" />
						<path d="M48.8,77.5v-0.9h-0.9C48.4,76.6,48.8,77,48.8,77.5L48.8,77.5z" />
						<rect x="48.8" y="76.6" width="4" height="4" />
						<rect x="52.8" y="76.6" width="4" height="4" />
						<path d="M57.7,80.7h2.4c0.5,0,0.9-0.4,0.9-0.9l0,0v-2.3c0-0.5-0.4-0.9-0.9-0.9l0,0h-3.2v4h0.8V80.7z" />
						<path d="M65,77.5v-0.9h-0.9C64.6,76.6,65,77,65,77.5L65,77.5z" />
						<path d="M64.1,80.7c0.5,0,0.9-0.4,0.9-0.9l0,0v0.9H64.1z" />
						<rect x="65" y="76.6" width="4" height="4" />
						<path d="M69.9,80.7c-0.5,0-0.9-0.4-0.9-0.9l0,0v0.9H69.9z" />
						<path d="M80.3,80.7c0.5,0,0.9-0.4,0.9-0.9l0,0v0.9H80.3z" />
						<rect x="81.1" y="76.6" width="4" height="4" />
						<rect x="85.2" y="76.6" width="4" height="4" />
						<rect x="89.2" y="76.6" width="4" height="4" />
						<rect x="93.3" y="76.6" width="4" height="4" />
						<path d="M101.4,79.8c0,0.5-0.4,0.9-0.9,0.9l0,0h-3.2v-4h4L101.4,79.8L101.4,79.8z" />
						<path d="M40.7,81.6v-0.9h-0.9C40.3,80.7,40.7,81.1,40.7,81.6L40.7,81.6z" />
						<path d="M39.8,84.7c0.5,0,0.9-0.4,0.9-0.9l0,0v0.9H39.8z" />
						<rect x="40.7" y="80.7" width="4" height="4" />
						<path d="M45.6,84.7c-0.5,0-0.9-0.4-0.9-0.9l0,0v0.9H45.6z" />
						<path d="M48.8,83.9c0,0.5,0.4,0.9,0.9,0.9l0,0H52c0.5,0,0.9-0.4,0.9-0.9l0,0v-3.2h-4v3.2H48.8z" />
						<path d="M53.7,80.7h-0.9v0.9C52.8,81.1,53.2,80.7,53.7,80.7L53.7,80.7z" />
						<path d="M61.8,80.7c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v3.2h4v-4L61.8,80.7L61.8,80.7z" />
						<rect x="65" y="80.7" width="4" height="4" />
						<rect x="69" y="80.7" width="4" height="4" />
						<rect x="73" y="80.7" width="4" height="4" />
						<rect x="77.1" y="80.7" width="4" height="4" />
						<rect x="81.1" y="80.7" width="4" height="4" />
						<path d="M86,80.7h-0.9v0.9C85.2,81.1,85.6,80.7,86,80.7L86,80.7z" />
						<path d="M89.2,81.6v-0.9h-0.9C88.8,80.7,89.2,81.1,89.2,81.6L89.2,81.6z" />
						<path d="M89.2,83.9c0,0.5,0.4,0.9,0.9,0.9l0,0h3.2v-4h-4L89.2,83.9L89.2,83.9z" />
						<path d="M97.3,83.9c0,0.5-0.4,0.9-0.9,0.9l0,0h-3.2v-4h4L97.3,83.9L97.3,83.9z" />
						<path d="M98.2,80.7h-0.9v0.9C97.3,81.1,97.7,80.7,98.2,80.7L98.2,80.7z" />
						<path d="M35.8,84.7h-2.3c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v2.3c0,0.5,0.4,0.9,0.9,0.9l0,0h3.2v-4h-0.9V84.7z" />
						<rect x="36.6" y="84.7" width="4" height="4" />
						<rect x="40.7" y="84.7" width="4" height="4" />
						<path d="M45.6,88.8h2.3c0.5,0,0.9-0.4,0.9-0.9l0,0v-2.3c0-0.5-0.4-0.9-0.9-0.9l0,0h-3.2v4L45.6,88.8z" />
						<path d="M52,88.8c0.5,0,0.9-0.4,0.9-0.9l0,0v0.9H52z" />
						<path d="M56.9,87.9v-2.3c0-0.5-0.4-0.9-0.9-0.9l0,0h-2.3c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v3.2h4v-0.9H56.9z" />
						<rect x="60.9" y="84.7" width="4" height="4" />
						<rect x="65" y="84.7" width="4" height="4" />
						<path d="M73,87.9c0,0.5-0.4,0.9-0.9,0.9l0,0h-3.2v-4h4L73,87.9L73,87.9z" />
						<path d="M73.9,84.7H73v0.9C73,85.1,73.4,84.7,73.9,84.7L73.9,84.7z" />
						<path d="M77.1,85.6v-0.9h-0.9C76.7,84.7,77.1,85.1,77.1,85.6L77.1,85.6z" />
						<rect x="77.1" y="84.7" width="4" height="4" />
						<path d="M85.2,87.9c0,0.5-0.4,0.9-0.9,0.9l0,0h-3.2v-4h4L85.2,87.9L85.2,87.9z" />
						<path d="M36.6,89.6v-0.9h-0.9C36.3,88.8,36.6,89.2,36.6,89.6L36.6,89.6z" />
						<path d="M35.8,92.8c0.5,0,0.9-0.4,0.9-0.9l0,0v0.9H35.8z" />
						<rect x="36.6" y="88.8" width="4" height="4" />
						<rect x="40.7" y="88.8" width="4" height="4" />
						<path d="M45.6,88.8h-0.9v0.9C44.7,89.2,45.1,88.8,45.6,88.8L45.6,88.8z" />
						<path d="M52,88.8h-2.3c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0V92c0,0.5,0.4,0.9,0.9,0.9l0,0h3.2v-4H52V88.8z" />
						<path d="M56.9,92c0,0.5-0.4,0.9-0.9,0.9l0,0h-3.2v-4h4L56.9,92L56.9,92z" />
						<path d="M60.9,92c0,0.5,0.4,0.9,0.9,0.9l0,0h2.3c0.5,0,0.9-0.4,0.9-0.9l0,0v-3.2h-4L60.9,92L60.9,92z" />
						<path d="M65.8,88.8h-0.9v0.9C65,89.2,65.3,88.8,65.8,88.8L65.8,88.8z" />
						<path d="M77.1,92c0,0.5,0.4,0.9,0.9,0.9l0,0h2.3c0.5,0,0.9-0.4,0.9-0.9l0,0v-3.2h-4V92H77.1z" />
						<path d="M82,88.8h-0.9v0.9C81.1,89.2,81.5,88.8,82,88.8L82,88.8z" />
						<path d="M86,88.8c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v3.2h4v-4L86,88.8L86,88.8z" />
						<path d="M90.1,92.8h2.3c0.5,0,0.9-0.4,0.9-0.9l0,0v-2.3c0-0.5-0.4-0.9-0.9-0.9l0,0h-3.2v4h0.9V92.8z" />
						<path d="M96.5,92.8c0.5,0,0.9-0.4,0.9-0.9l0,0v0.9H96.5z" />
						<path d="M101.4,92v-2.3c0-0.5-0.4-0.9-0.9-0.9l0,0h-2.3c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v3.2h4V92H101.4z" />
						<path d="M33.5,92.8c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v3.2h4v-4L33.5,92.8L33.5,92.8z" />
						<rect x="36.6" y="92.8" width="4" height="4" />
						<path d="M44.7,96c0,0.5-0.4,0.9-0.9,0.9l0,0h-3.2v-4h4V96H44.7z" />
						<path d="M65.8,92.8c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v3.2h4v-4L65.8,92.8L65.8,92.8z" />
						<path d="M69.9,96.9h2.3c0.5,0,0.9-0.4,0.9-0.9l0,0v-2.3c0-0.5-0.4-0.9-0.9-0.9l0,0H69v4h0.9V96.9z" />
						<path d="M84.3,96.9c0.5,0,0.9-0.4,0.9-0.9l0,0v0.9H84.3z" />
						<rect x="85.2" y="92.8" width="4" height="4" />
						<path d="M90.1,92.8h-0.9v0.9C89.2,93.2,89.6,92.8,90.1,92.8L90.1,92.8z" />
						<path d="M94.1,92.8c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v3.2h4v-4L94.1,92.8L94.1,92.8z" />
						<rect x="97.3" y="92.8" width="4" height="4" />
						<path d="M32.6,100.1c0,0.5,0.4,0.9,0.9,0.9l0,0h3.2v-4h-4v3.1H32.6z" />
						<path d="M40.7,100.1c0,0.5-0.4,0.9-0.9,0.9l0,0h-3.2v-4h4v3.1H40.7z" />
						<path d="M41.5,96.9h-0.9v0.9C40.7,97.3,41.1,96.9,41.5,96.9L41.5,96.9z" />
						<path d="M52,96.9h-2.3c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v2.3c0,0.5,0.4,0.9,0.9,0.9l0,0h3.2v-4H52V96.9z" />
						<rect x="52.8" y="96.9" width="4" height="4" />
						<path d="M57.7,100.9h2.4c0.5,0,0.9-0.4,0.9-0.9l0,0v-2.3c0-0.5-0.4-0.9-0.9-0.9l0,0h-3.2v4h0.8V100.9z" />
						<path d="M65,100.1c0,0.5,0.4,0.9,0.9,0.9l0,0h2.2c0.5,0,0.9-0.4,0.9-0.9l0,0v-3.2h-4V100.1z" />
						<path d="M69.9,96.9H69v0.9C69,97.3,69.4,96.9,69.9,96.9L69.9,96.9z" />
						<path d="M84.3,96.9H82c-0.5,0-0.9,0.4-0.9,0.9l0,0l0,0v2.3c0,0.5,0.4,0.9,0.9,0.9l0,0h3.2v-4h-0.9V96.9z" />
						<path d="M89.2,100.1c0,0.5-0.4,0.9-0.9,0.9l0,0h-3.2v-4h4L89.2,100.1z" />
						<path d="M93.3,100.1c0,0.5,0.4,0.9,0.9,0.9l0,0h3.2v-4h-4L93.3,100.1L93.3,100.1z" />
						<path d="M101.4,100.1c0,0.5-0.4,0.9-0.9,0.9l0,0h-3.2v-4h4L101.4,100.1L101.4,100.1z" />
						<path d="M1.1-0.2c-0.5,0-0.9,0.4-0.9,0.9v26.6c0,0.5,0.4,0.9,0.9,0.9h26.6c0.5,0,0.9-0.4,0.9-0.9V0.7c0-0.5-0.4-0.9-0.9-0.9
		C27.7-0.2,1.1-0.2,1.1-0.2z M24.5,7.9v15.7c0,0.3-0.2,0.5-0.5,0.5l0,0H4.8c-0.3,0-0.5-0.2-0.5-0.5l0,0V4.3c0-0.3,0.2-0.5,0.5-0.5
		l0,0H24c0.3,0,0.5,0.2,0.5,0.5l0,0V7.9z"/>
						<path d="M20.5,16V8.8c0-0.5-0.4-0.9-0.9-0.9H9.2c-0.5,0-0.9,0.4-0.9,0.9v10.4c0,0.5,0.4,0.9,0.9,0.9h10.4c0.5,0,0.9-0.4,0.9-0.9V16
		z"/>
						<path d="M73.9-0.2c-0.5,0-0.9,0.4-0.9,0.9v26.6c0,0.5,0.4,0.9,0.9,0.9h26.6c0.5,0,0.9-0.4,0.9-0.9V0.7c0-0.5-0.4-0.9-0.9-0.9
		C100.5-0.2,73.9-0.2,73.9-0.2z M97.3,7.9v15.7c0,0.3-0.2,0.5-0.5,0.5H77.6c-0.3,0-0.5-0.2-0.5-0.5V4.3c0-0.3,0.2-0.5,0.5-0.5h19.2
		c0.3,0,0.5,0.2,0.5,0.5V7.9z"/>
						<path d="M93.3,16V8.8c0-0.5-0.4-0.9-0.9-0.9H82c-0.5,0-0.9,0.4-0.9,0.9v10.4c0,0.5,0.4,0.9,0.9,0.9h10.4c0.5,0,0.9-0.4,0.9-0.9
		C93.3,19.2,93.3,16,93.3,16z"/>
						<path d="M1.1,72.6c-0.5,0-0.9,0.4-0.9,0.9v26.6c0,0.5,0.4,0.9,0.9,0.9h26.6c0.5,0,0.9-0.4,0.9-0.9V73.5c0-0.5-0.4-0.9-0.9-0.9H1.1z
		 M24.5,80.7v15.7c0,0.3-0.2,0.5-0.5,0.5H4.8c-0.3,0-0.5-0.2-0.5-0.5V77.1c0-0.3,0.2-0.5,0.5-0.5H24c0.3,0,0.5,0.2,0.5,0.5V80.7z"/>
						<path d="M20.5,88.8v-7.2c0-0.5-0.4-0.9-0.9-0.9H9.2c-0.5,0-0.9,0.4-0.9,0.9V92c0,0.5,0.4,0.9,0.9,0.9h10.4c0.5,0,0.9-0.4,0.9-0.9
		V88.8z"/>
					</g>
				</svg>

				{/* <img src="/qr.svg" alt='QR' /> */}
			</div>
		);
	}

}

export default QR;
