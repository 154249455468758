import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withGetScreen } from 'react-getscreen'
import InsurancesDesktop from './InsurancesDesktop'
import InsurancesMobile from './InsurancesMobile'

class Insurances extends Component {
  render() {
    if (this.props.isMobile()) {
      return <InsurancesMobile />;
    } else {
      return <InsurancesDesktop />;
    }
  }
}

const options = { mobileLimit: 1024, shouldListenOnResize: true  }
export default connect()(withGetScreen(Insurances, options))
