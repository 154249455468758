import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import AnimateHeight from 'react-animate-height';

class LifeIns extends React.Component {
    state = {
        height: {
            0: 0, 1: 0
        },
    }

    toggle = (index) => {
        const { height } = this.state;
        height[index] = height[index] === 0 ? 'auto' : 0
        this.setState({
            height: height,
        });
    };

    render() {

        return (
            <div className='tab-info' id='lifeInsInfo'>

                <h4><span className='icon'><img src='/icons/health.svg' alt='' /></span>Здравна застраховка</h4>
                <p><a href='/' className='link-add-ins'><span className='icon'><img src='/icons/shield.svg' alt='' /></span>Добавете като съществуваща застраховка в приложението</a></p>
                <p className="bold">Здравната застраховка може да бъде сключена индивидуално или за група хора.</p>
                <p className='uppercase'>Пакетите здравна застраховка включват:</p>
                <ul>
                    <li>Подобряване на здравето и предпазване от заболяване</li>
                    <li>Извънболнична медицинска помощ</li>
                    <li>Болнична медицинска помощ</li>
                    <li>Услуги, свързани с битови и други допълнителни условия при предоставяне на медицинска помощ</li>
                    <li>Възстановяване на разходи</li>
                </ul>
                <p className='space-bottom-xl'>Здравната застраховка може да се сключи в различни вариации. Спрямо Вас, Амарант България може да предложи различен тип здравна застраховка, която да е максимално полезна в своята всъщност. </p>
                <p className='uppercase'>Ето и какви различни вариации здравна застраховка:</p>
                <ul>
                    <li>Индивидуална здравна застраховка</li>
                    <li>Индивидуална здравна застраховка с нарастваща засрахователна сума</li>
                    <li>Семейна здравна застраховка</li>
                    <li>Групова здравна застраховка</li>
                    <li>Индивидуална здравна застраховка с медицински разноски</li>
                    <li>Индивудуална здравна застраховка с медицински ранзоски, комбинирана със злополука</li>
                </ul>
                <div className="open-close">
                    <div className={this.state.height[0] === 0 ? 'close' : 'open'} onClick={() => this.toggle(0)}>
                        <div><span className='icon'><img src='/icons/shield.svg' alt='' /></span>Какво е необходимо за добавяне в приложението? </div>
                    </div>
                    <AnimateHeight className='open-close-info' height={this.state.height[0]}>
                        <div>
                            <p>Единственият документ, от който имате нужда, за да добавите съществуваща застраховка в приложението, за да можете да я управлявате и да следите нейните срокове е номерътт на застраховката и да посочите с коя застрахователна компания е сключена</p>
                            <p><Link className='color-2' to='/download-app'>Свали приложението</Link></p>
                        </div>
                    </AnimateHeight>
                </div>

            </div>
        )
    }
}
export default connect()(LifeIns)