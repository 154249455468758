import React from 'react'
import { connect } from 'react-redux'
import AnimateHeight from 'react-animate-height';
import { Link } from 'react-router-dom';

class Accident extends React.Component {
    state = {
        height: {
            0: 0, 1: 0
        },
    }

    toggle = (index) => {
        const { height } = this.state;
        height[index] = height[index] === 0 ? 'auto' : 0
        this.setState({
            height: height,
        });
    };

    render() {

        return (
            <div className='tab-info' id='accidentInfo'>

                <h4><span className='icon'><img src='/icons/professional-white.svg' alt='' /></span>Професионални Отговорности</h4>
                <p>Застраховката покрива рискове за трайна и временна загуба на работоспособност, медицински разноски вследствие трудова и битова злополука, както и смърт. При настъпване на застрахователно събитие в рамките на покрития риск компанията изплаща на Вас, или на посочено от Вас ползващо лице, договорената в полицата застрахователна сума и обезщетение.</p>
                <div className="open-close">
                    <div className={this.state.height[0] === 0 ? 'close' : 'open'} onClick={() => this.toggle(0)}>
                        <div><span className='icon'><img src='/icons/shield.svg' alt='' /></span>Какво е необходимо за добавяне в приложението? </div>
                    </div>
                    <AnimateHeight className='open-close-info' height={this.state.height[0]}>
                        <div>
                            <p>Единственият документ, от който имате нужда, за да добавите съществуваща застраховка в приложението, за да можете да я управлявате и да следите нейните срокове е номерътт на застраховката и да посочите с коя застрахователна компания е сключена</p>
                            <p><Link className='color-2' to='/download-app'>Свали приложението</Link></p>
                        </div>
                    </AnimateHeight>
                </div>

            </div>
        )
    }
}
export default connect()(Accident)