import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import AnimateHeight from 'react-animate-height';

class TravelCancellation extends React.Component {
    state = {
        height: {
            0: 0, 1: 0
        },
    }

    toggle = (index) => {
        const { height } = this.state;
        height[index] = height[index] === 0 ? 'auto' : 0
        this.setState({
            height: height,
        });
    };

    render() {

        return (
            <div className='tab-info' id='travelCancellationInfo'>

                <h4><span className='icon'><img src='/icons/plain.svg' alt='' /></span>Застраховка отмяна на пътуване:</h4>
                <p>Застраховката „Отмяна на пътуване“ е напълно доброволна и е предназначена за Вашите предстоящи туристически или бизнес пътувания на територията на България или чужбина. Сключва се срещу риска от отменено или пропуснато пътуване поради медицински или немедицински причини, независещи от Вас и непредвидими към момента на сключване на застраховката.</p>
                <p> Застрахователят Ви възстановява разходи за самолетни билети, хотелски резервации и други туристически и бизнес услуги, извършени предварително, които не могат да бъдат възстановени при отмяна на пътуването.</p>
                <div className="open-close">
                    <div className={this.state.height[0] === 0 ? 'close' : 'open'} onClick={() => this.toggle(0)}>
                        <div><span className='icon'><img src='/icons/shield.svg' alt='' /></span>Какво е необходимо за добавяне в приложението? </div>
                    </div>
                    <AnimateHeight className='open-close-info' height={this.state.height[0]}>
                        <div>
                            <p>Единственият документ, от който имате нужда, за да добавите съществуваща застраховка в приложението, за да можете да я управлявате и да следите нейните срокове е номерътт на застраховката и да посочите с коя застрахователна компания е сключена</p>
                            <p><Link className='color-2' to='/download-app'>Свали приложението</Link></p>
                        </div>
                    </AnimateHeight>
                </div>

            </div>
        )
    }
}
export default connect()(TravelCancellation)