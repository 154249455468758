import React, { Component } from 'react'
import { connect } from 'react-redux'
import AnimateHeight from 'react-animate-height';

class FaqsListInfo6 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: {
                0: 0, 1: 0, 2: 0, 3: 0, 4: 0,
            },

        }
    }

    toggle = (index) => {
        const { height } = this.state;
        height[index] = height[index] === 0 ? 'auto' : 0
        this.setState({
            height: height
        });
    };

    render() {
        // const { height } = this.state;

        return (
            <div className='faqs-list-info'>

                <div className='faq-info'>
                    <div className={this.state.height[0] === 0 ? 'open' : 'close'} onClick={() => this.toggle(0)}>
                        <div>Какво се случва с личните ми данни и колко добре са защитени те?</div>
                    </div>
                    <AnimateHeight height={this.state.height[0]} >
                        <div>
                            <p>Ние в Амарант имаме строга политика относно защитата на Вашите лични данни и предприемаме необходимите мерки по всички стандарти, за да може Вашите лични данни да са винаги криптирани и недостъпни за 3ти лица. По-подробно можете да се запознаете във Вашия профил, секция „Правна информация“ – „Политика за поверителност“.</p>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[1] === 0 ? 'open' : 'close'} onClick={() => this.toggle(1)}>
                        <div>Какви „други документи“ мога да добавям в мобилното приложение?</div>
                    </div>
                    <AnimateHeight height={this.state.height[1]} >
                        <div>
                            <p>За да използвате най-ефективно всички функционалности на мобилното приложение, можете предварително да си въведете данните за съществуващи полици, шофьорска книжка, лична карта и други документи. Мобилното приложение ще ви известява преди изтичането им.</p>
                        </div>
                    </AnimateHeight>
                </div>


            </div>
        )
    }

}

export default connect()(FaqsListInfo6)