import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import {QR} from '../../components'
import './styles.scss';

class DownloadApp extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    document.getElementsByTagName('body')[0].className = 'download-app-body';
  }
  componentWillUnmount() {
    document.getElementsByTagName('body')[0].className = '';
  }

  render() {
    return (
      <div className="download-app">
        <div className='container container-main'>
          <div className='row'>
            <div className='col'>
              <div className='appstore'>
                <h3 className='apple'>За потребители на iPhone:</h3>
                <ul className='ul'>
                  <li>Сканирайте QR кода</li>
                  <li>Свалете приложението от App Store</li>
                  <li>Регистрирайте се с вашия телефонен номер и имейл адрес</li>
                </ul>
              </div>
            </div>
            <div className='col col-mockup'>
              <div className='phone-mockup'>
                <div className='phone-img'>
                  <img src='/imgs/amarant-download.png' alt='Свалето приложението' />
                  <QR styleName='qr-code qr-box-white'/>
                </div>
              </div>
            </div>
            <div className='col'>
              <div className='googleplay'>
                <h3 className='android'>За потребители на Android:</h3>
                <ul className='ul'>
                  <li>Сканирайте QR кода</li>
                  <li>Свалете приложението от App Store</li>
                  <li>Регистрирайте се с вашия телефонен номер и имейл адрес</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className='footer-download-app'>
          <div className='container'>
            <div className='row'>
              <div className='col'>
                <NavLink to='/'>Начини на плащане</NavLink>
              </div>
              <div className='col'>
                <NavLink to='/faqs'>Често задавни въпроси</NavLink>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }

}

export default connect()(DownloadApp)