import React, { Component } from 'react'
import { connect } from 'react-redux'
import AnimateHeight from 'react-animate-height';

class FaqsListInfo3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: {
                0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0,
            },

        }
    }

    toggle = (index) => {
        const { height } = this.state;
        height[index] = height[index] === 0 ? 'auto' : 0
        this.setState({
            height: height
        });
    };

    render() {
        // const { height } = this.state;

        return (
            <div className='faqs-list-info'>

                <div className='faq-info'>
                    <div className={this.state.height[0] === 0 ? 'open' : 'close'} onClick={() => this.toggle(0)}>
                        <div>Не ми връща оферта за застраховка гражданска отговорност. Как мога да получа оферта?</div>
                    </div>
                    <AnimateHeight height={this.state.height[0]} >
                        <div>
                            <p>При неполучаване на оферта, най-вероятно става въпрос за технически проблеми и ще трябва да повторите процеса отначало. Ако се появява същият проблем, опитайте отново след няколко часа и при евентуален неуспех моля да се обърнете към нас:</p>
                            <ul className='ul'>
                                <li>На телефон: 08******** за решение на момента</li>
                                <li>ДНа нашия мейл: ХХХХХ@fidweb.net за отговор до 24 часа</li>
                            </ul>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[1] === 0 ? 'open' : 'close'} onClick={() => this.toggle(1)}>
                        <div>Сканирах си талона,но не ми извлича данните за автомобила. Как мога да получа ценова оферта?</div>
                    </div>
                    <AnimateHeight height={this.state.height[1]} >
                        <div>
                            <p>Ако след неколкократно сканиране, данните Ви не бъдат извлечени от нашия чатбот Том,  тогава най-вероятно Вашето мобилно устройство не отговаря на критериите, необходими за сканиране или не е достатъчно добро качеството на снимката, и ще се наложи да попълните данните собственоръчно.</p>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[2] === 0 ? 'open' : 'close'} onClick={() => this.toggle(2)}>
                        <div>Как се образува персоналната оферта?</div>
                    </div>
                    <AnimateHeight height={this.state.height[2]} >
                        <div>
                            <p>Всеки клиент има уникални данни спрямо него (шофьорски стаж, нарушения и др.), както и спрямо неговия автомобил (година на производство, обем на двигателя и др.). По този начин застрахователните компании могат да предоставят „персонални“ оферти, чиято цел е да отговарят напълно на уникалните данни спрямо водач и автомобил.</p>
                        </div>
                    </AnimateHeight>
                </div>

            </div>
        )
    }

}

export default connect()(FaqsListInfo3)