import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { GoogleApiWrapper, Map, InfoWindow, } from 'google-map-react';
import GoogleMapReact from 'google-map-react';
import SearchBox from './SearchBox';
import './styles.scss';

const AddressMarker = ({ text }) => <div className='gmap-marker'>
  <span className='gmap-pin'><img src='/icons/gmap-pin.svg' alt='' /></span>{text}
</div>;


class Offices extends Component {
  static defaultProps = {
    center: {
      lat: 42.6904114,
      lng: 23.3154911
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      officesList: [
        // { name: '', address: '', email: '', phone: '', phone2: '', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: , lng:  }
        { name: 'Централен офис АМАРАНТ', address: 'ул. Хан Аспарух 5, гр. София', email: 'office@amarantbg.com', phone: '02/492 13 53', phone2: '02/492 13 53', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: true, lat: 42.6906042, lng: 23.314783 },
        { name: 'гр. София - КАТ', address: 'ул. Драган Цанков 33 А, гр. София', email: 'amarant.kat_sofia@abv.bg', phone: '+359 896 116 631', phone2: '', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.6640561, lng: 23.3557942 },
        { name: 'гр. София - Милка Бочева', address: 'ул. Челопешко шосе 10-12', email: 'm.bocheva@ms-trucks.eu', phone: '+359 895 624412', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.7105696, lng: 23.4325299 },
        { name: 'гр. София - Мултифейс', address: 'кв.Военна рампа, бул. Първа българска армия 14', email: 'premierservice@abv.bg', phone: '+359 899 145371', phone2: '+359 899 145375', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.7236806, lng: 23.322433 },
        { name: 'гр. София - Силвия Добрянова', address: 'кв. Бояна, ул. Иваница Данчев 7', email: 'silvia@amarantbg.com', phone: '+359 887396019', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.6493047, lng: 23.2646791 },
        { name: 'гр. София - Красно село', address: 'бул. Гоце Делчев 49', email: 'ilian72@abv.bg', phone: '+359 898 444406', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.6751998, lng: 23.2901986 },
        { name: 'гр. София Борово', address: 'ул. Родопски извор 2', email: 'borovo@amarantbg.com', phone: '+359 878 559195', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.6676165, lng: 23.2839922 },
        { name: 'гр. София Обча купел', address: 'бул. Президент Линкълн 16', email: 'bis_d2@abv.bg', phone: '+359 897 097009', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.6856572, lng: 23.2674296 },
        { name: 'гр. София - Офис Емил Марков', address: 'жк.Гоце Делчев, бул. Гоце Делчев, бл.102', email: 'emil_markov@amarantbg.com', phone: '+359 888 344786', phone2: '+359 885 704062', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.6659485, lng: 23.301264 },
        { name: 'гр. София Офис Лозенец', address: 'кв.Лозенец, ул. Банат 11, офис 5', email: 'genchev.lic@gmail.com', phone: '+359 899 990595', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.6615728, lng: 23.3150098 },
        /** Варна */
        { name: 'гр. Варна', address: 'кв. Младост, Пазар Панелчето, павильон 65, Срещу блок 108- вх.10', email: 'info_kat@amarantbg.com', phone: '+359 894 466267', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.233925, lng: 27.8740968 },
        { name: 'гр. Варна', address: 'ул. Андрей Сахаров 15 А', email: 'varna_nadejda@amarantbg.com', phone: '+359 087 303684', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.223057, lng: 27.9012974 },
        { name: 'с. Галата, обл.Варна', address: 'ул. Васил Левски 49', email: 'galata@amarantbg.com', phone: '+359 882 432666', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.0185081, lng: 24.2792281 },
        /** Пловдив */
        { name: 'гр. Пловдив ЦО', address: 'ул. Раковски - 31', email: 'plovdiv@amarantbg.com', phone: '+359 887569130', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.1408137, lng: 24.7247156 },
        { name: 'гр. Пловдив КАТ - Пеев', address: 'ул. Напредък 2, гр. Пловдив', email: 'info_kat@amarantbg.com', phone: '+359 894 466 267', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.1650832, lng: 24.719579 },
        { name: 'гр. Пловдив КАТ', address: 'ул. Напредък - 2, гр. Пловдив', email: 'petia@amarantbg.com', phone: '+359 896 116 631', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.1650832, lng: 24.719579 },
        { name: 'гр. Пловдив - 1', address: 'ул. Раковски - 31, Пловдив', email: 'office@amarantbg.com', phone: '+359 887 569 130', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.1647392, lng: 24.719113 },
        { name: 'гр. Пловдив - 2', address: 'ул. Огражден - 10, гр. Пловдив', email: 'plovdiv_city@amarantbg.com', phone: '+359 895 663 537', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.1622, lng: 24.7566 },
        { name: 'гр. Пловдив - 3', address: 'ул. Мали Богдан - 35, Пловдив', email: 'savova@amarantbg.com', phone: '+359 899 424 270', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.1449239, lng: 24.7584706 },
        { name: 'Пловдив Минчо', address: 'ул. Борба 22', email: 'office_plovdiv@amarantbg.com', phone: '+359 899990277', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.164128, lng: 24.7555286 },
        { name: 'с. Белозем', address: 'ул. Подем 21 Б, общ.Раковски, обл.Пловдив', email: 'belozem@amarantbg.com', phone: '+359  882 935554', phone2: '+359  896 124270', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.2031143, lng: 25.0489617 },
        { name: 'с. Войводиново-Красимира Какалова, обл.Пловдив', address: 'ул. Кокиче 2 А', email: 'k_kakalova@amarantbg.com', phone: '+359 898848282', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.2031143, lng: 25.0489617 },
        /** Бургас */
        { name: 'гр. Бургас', address: 'бул. Иван Вазов - 75', email: 'info_kat@amarantbg.com', phone: '+359 899 223521', phone2: "+359 896 890776", workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.490856, lng: 27.464032 },
        { name: 'гр. Бургас', address: 'ул. Левски - 87', email: 'dimitrina@amarantbg.com', phone: '+359 884 199990', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.4975907, lng: 27.4628669 },
        { name: 'гр. Бургас', address: 'ул. Константин Фотинов - 46', email: 'burgas@amarantbg.com', phone: '+359 878 460006', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.4953741, lng: 27.475156 },
        { name: 'гр. Бургас', address: 'бул. Мария Луиза (гара Владимир Павлов. Автомивка Делукс)', email: 'kristina@amarantbg.com', phone: '+359 893 334471', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.5011169, lng: 27.4554823 },
        { name: 'гр. Бургас - Ина', address: 'ул. Копривщица - 29', email: 'dani@amarantbg.com', phone: '+359 883 426443', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.509321, lng: 27.4742612 },
        { name: 'гр. Бургас - Кунка', address: 'ул. Стара планина - 3', email: 'kunka@amarantbg.com', phone: '+359 888 916379', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.5036406, lng: 27.4713902 },
        /** Велико Търново */
        { name: 'гр. Велико Търново - Петя ВТ', address: 'кв.Слънчев дом, ул. Слънце 2, вх.Д', email: 'petq_vt@amarantbg.com', phone: '+359 884 620819', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.0821493, lng: 25.6112957 },
        { name: 'гр. Велико Търново - 2', address: 'ул. Цанко Церковски 37, офис 4', email: 'office_veliko.tarnovo@amarantbg.com', phone: '+359 885 417272', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.07928, lng: 25.62786 },
        /** Русе */
        { name: 'гр. Русе', address: 'ул. Мария Луиза - 16', email: 'v.yordanov@amarantbg.com', email2: 'silvena@amarantbg.com', phone: '+359 897904647', phone2: '+359 885261676', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.8406145, lng: 25.9535827 },
        { name: 'гр. Русе 3', address: 'ул. Ангел Кънчев - 30', email: 'krasimir@amarantbg.com', phone: '+359 889789353', phone2: '0895774448', phone3: ';082871142', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.8484323, lng: 25.9579581 },
        { name: 'гр. Русе - 3-А', address: 'ул. Шипка - 44', email: 'ruse@amarantbg.com', phone: '+359 889994073', phone2: '082871142', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.8411628, lng: 25.9639557 },
        { name: 'гр. Русе - Мая', address: 'бул. Бозвели - 18 (паркинг Билла)', email: 'maya_ivanova@amarantbg.com', phone: '+359 89787907', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.8387338, lng: 25.9539657 },
        /** Асеновград */
        { name: 'гр. Асеновград - СФ', address: 'ул. Камара 1', email: 'asenovgrad@amarantbg.com', phone: '+359 889 4601231', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.0232372, lng: 24.8417673 },
        /** Балчик */
        { name: 'гр. Балчик - Светла', address: 'ул. Черно море - 43', email: '', phone: '+359 893 509791', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.410068, lng: 28.1663357 },
        { name: 'гр. Балчик - Цветелина', address: 'ул. Генерал Стоянов', email: '', phone: '+359 879 661270', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: null, lng: null },
        /** Благоевград */
        { name: 'Благоевград', address: 'ул. Крали Марко - 8', email: 'info_blagoevgrad@amarantbg', phone: '+359 898 648666', phone2: '073/872563', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.0169973, lng: 23.0967396 },
        /** Брезово */
        { name: 'Офис Брезово', address: 'ул. 9-ти септември - 14, гр. Брезово', email: 'brezovo@amarantbg.com', phone: '+359 879 171350', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.0169973, lng: 23.0967396 },
        /** Бяла Слатина */
        { name: 'гр. Бяла Слатина', address: 'ул. Васил Левски - 14', email: 'biala_slatina@amarantbg.com', phone: '+359 894 466232', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.4717425, lng: 23.9466072 },
        /** Брезник */
        { name: 'гр. Брезник', address: 'ул. Георги Бунджулов - 31', email: 'breznik@amarantbg.com', phone: '+359 876 709170', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.74126, lng: 22.90808 },
        { name: 'гр. Брезник 2', address: 'ул. Владо Радославов - 1', email: 'kubrat@amarantbg.com', phone: '+359 878 415132', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.739921, lng: 22.9103603 },
        /** Велинград */
        { name: 'гр. Велинград', address: 'бул. Съединение', email: 'кoev@amarantbg.com', phone: '+359 887 490908', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.0350105, lng: 23.9898149 },
        { name: 'гр. Велинград', address: 'ул. Юндола - 1 А, ет.1', email: 'office_velingrad@amarantbg.com', phone: '+359 879 010830', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.0276219, lng: 23.9809932 },
        { name: 'гр. Велинград', address: 'пл.Възраждане', email: 'velingrad@amarantbg.com', phone: '+359 890 366344', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.0275439, lng: 23.9915523 },
        /** Видин */
        { name: 'гр. Видин', address: 'ул. Цар Симеон Велики - 40', email: 'vidin@amarantbg.com', phone: '+359 999 987908', phone2: '094/600051', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.9897639, lng: 22.8744746 },
        { name: 'гр. Видин 2', address: 'ул. Широка - 19', email: '', phone: '+359 898 759992', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.9941767, lng: 22.8729993 },
        { name: 'гр. Видин 3', address: 'ул. Широка - 37', email: '', phone: '+359 890 988489', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.9948361, lng: 22.8710897 },
        /** Враца */
        { name: 'гр. Враца - 1', address: 'ул. Втори Юни - 29', email: 'vraca@amarantbg.com', phone: '+359 895 515072', phone2: '+359 886 868038', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.2004663, lng: 23.5526743 },
        { name: 'гр. Враца - 2', address: 'ул. Екзарх Йосиф - 44', email: 'info_kat@amarantbg.com', phone: '+359 877 586648', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.2053438, lng: 23.5595206 },
        /** Вълчи дол */
        { name: 'гр. Вълчи дол - Елена', address: 'ул. Тодор Каблешков - 19', email: '', phone: '+359 879 587796', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.4065768, lng: 27.5493505 },
        /** Вършец */
        { name: 'гр. Вършец', address: 'Бул. България - 6', email: 'jasmina_jarkova@amarantbg.com', phone: '+359 877 262740', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.1919784, lng: 23.2859786 },
        /** Габрово */
        { name: 'гр. Габрово', address: 'ул. Петко Каравелов - 5', email: 'gabrovo@amarantbg.com', phone: '+359 894 399072', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.8718737, lng: 25.3181957 },
        { name: 'гр. Габрово', address: 'бул. Могильов - 65', email: 'office_gabrovo@amarantbg.com', phone: '+359 895 515010', phone2: '+359 898 937785', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.8879373, lng: 25.3131125 },
        /** Горна Оряховица */
        { name: 'гр. Горна Оряховица', address: 'ул. Иван Вазов - 20', email: 'gorna_oryahovitsa@amarantbg.com', phone: '+359 884 207353', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.1239141, lng: 25.6909244 },
        /** Димитровград */
        { name: 'гр. Димитровград', address: 'ул. Димитър Димов - 1', email: 'dimitrovgrad@amarantbg.com', phone: '+359 896 948787', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.0565246, lng: 25.5986238 },
        /** Добрич */
        { name: 'гр. Добрич', address: 'ул. Вардар - 31', email: 'atanasov@amarantbg.com', phone: '+359 877 673566', phone2: '+359 898 673566', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.5651349, lng: 27.8352005 },
        { name: 'гр. Добрич - офис Звезда', address: 'ул. Любен Каравелов 2', email: 'nuray@amarantbg.com', phone: '+359 897 329737', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.5681849, lng: 27.821542 },
        /** Елин Пелин */
        { name: 'гр. Елин Пелин', address: 'пл. Николай Чудотворец - 5', email: 'elin_pelin@amarantbg.com', phone: '+359 899 987910', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.6700795, lng: 23.6016811 },
        { name: 'с. Петково - ЖАР, Николинка Бостанджиева', address: 'ул. Хан Аспарух - 65,, общ.Елин Пелин, обл.София, (търговски комплекс ЖАР)', email: '', phone: '+359 888 268520', phone2: '0725/66065', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.6700795, lng: 23.6016811 },
        /** Златоград */
        { name: 'гр. Златоград', address: 'ул. България - 114', email: 'eres_truck@abv.bg', phone: '+359 895 311215', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 41.3795498, lng: 25.0941494 },
        /** Казанлък */
        { name: 'Казанлък - 2', address: 'пл.Севтополис - 3', email: 'yanica.stefanova@amarantbg.com', phone: '+359 892 044535', phone2: '0431/63172', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.61915, lng: 25.39279 },
        { name: 'гр. Казанлък', address: 'ул. Шипченска епопея - 24', email: 'kazanlak@amarantbg.com', phone: '+359 899 748397', phone2: '0431/62078', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.6220117, lng: 25.3918077 },
        { name: 'с. Овощник', address: 'ул. Жельо Манолов - 43, общ.Казанлък, обл.Стара Загора', email: 'ovoshtnik@amarantbg.com', phone: '+359 893 444104', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.5772627, lng: 25.444161 },
        /** Калояново */
        { name: 'гр. Калояново', address: 'пл.Възраждане - 3', email: 'kaloyanovo@amarantbg.com', phone: '+359 899 161647', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.3561769, lng: 24.7313907 },
        /** Карлово */
        { name: 'Карлово Шел', address: 'Местност Борчетата, бензиностанция Шел', email: 'office_karlovo@amarantbg.com', phone: '+359 ', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.6316559, lng: 24.8143682 },
        /** Кнежа */
        { name: 'гр. Кнежа', address: 'ул. Марин Боев - 93', email: 'knezha@amarantbg.com', phone: '0899211178', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.4982399, lng: 24.0812352 },
        /** Козлодуй */
        { name: 'гр. Козлодуй', address: 'ул. Христо Ботев - 1, ТПК - Радецки', email: 'kozloduy@amarantbg.com', phone: '+359 898212656', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.7755794, lng: 23.7329578 },
        /** Костандово */
        { name: 'гр. Костандово', address: 'ул. Христо Ботев - 35', email: 'kostandovo@amarantbg.com', phone: '+359 894466232', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.0251471, lng: 24.1089781 },
        /** Костинброд */
        { name: 'с. Драговищица', address: 'ул. 23 - 1, общ. Костинброд', email: 'kostinbrod@amarantbg.com', phone: '+359 898558762', phone2: '0896879097', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: null, lng: null },

        /** Крумовград */
        { name: 'гр. Крумовград', address: 'ул. Трети март - 6', email: 'amarantkrumovgrad@abv.bg', phone: '+359 0877163525', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 41.4723036, lng: 25.6539461 },
        /** Куклен */
        { name: 'гр. Куклен', address: 'ул. Славянска - 3а', email: '', phone: '+359 894315828', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.0348304, lng: 24.7845219 },
        /** Кърджали */
        { name: 'гр. Кърджали', address: 'бул. България - 74', email: 'topalov@amarantbg.com', phone: '+359 36122539', phone2: '+359 887895760', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 41.636275, lng: 25.3718653 },
        { name: 'гр. Кърджали', address: 'ул. Републиканска - 26', email: 'kardjali@amarantbg.com', phone: '+359 879648161', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 41.6440279, lng: 25.3663247 },
        { name: 'с. Кирково', address: 'ул. Георги Кирков, обл.Кърджали', email: 'kirkovo@amarantbg.com', phone: '+359 0886474820 ', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 41.33081, lng: 25.36491 },
        { name: 'с. Джебел', address: 'ул. Иван Вазов - 13, обл.Кърджали', email: 'djebel@amarantbg.com', phone: '+359 887836761', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 41.496975, lng: 25.3011203 },
        /** Лом */
        { name: 'гр. Лом - 1', address: 'ул. Цар Петър - 1', email: 'lom@amarantbg.com', phone: '097161111', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.821698, lng: 23.2373441 },
        { name: 'гр. Лом - 2', address: 'ул. Софроний - 56', email: 'lom@amarantbg.com', phone: '0896653872', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.822074, lng: 23.2361532 },
        { name: 'гр. Лом - 3', address: 'ул. Цар Симеон - 31', email: 'lom@amarantbg.com', phone: '+359 97160006', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.8270469, lng: 23.2342197 },
        { name: "гр. Лом - 4, ГТП", address: 'л.Пристанищна - 50', email: 'bilyana_lom@amarantbg.com', phone: '0896839106', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.82562, lng: 23.2375 },
        { name: 'гр. Лом - 5', address: 'ул. Хан Аспарух - 6', email: 'lom@amarantbg.com', phone: '097160066', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.8210158, lng: 23.72502 },
        { name: 'гр. Лом - 6', address: 'ул. Цар Петър - 11', email: 'simeon_lom@amarantbg.com', phone: '0898518676', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.8206765, lng: 23.2362213 },
        /** Мадан */
        { name: 'гр. Мадан', address: 'ул. Обединение - 12, партер', email: 'madan@amarantbg.com', phone: '+359 899863646', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 41.5001708, lng: 24.9389316 },
        /** Мездра */
        { name: 'гр. Мездра', address: 'ул. Дунав - 11', email: 'galabina@amarantbg.com', phone: '0876277488', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.1464244, lng: 23.7017604 },
        { name: 'гр. Мездра', address: 'ул. Св.Патриарх Евтимий - 29 А', email: 'office_mezdra@amarantbg.com', phone: '+359 894874995', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.1445972, lng: 23.7040367 },
        { name: 'гр. Мездра', address: 'ул. Боденско шосе - 3', email: 'dangelova@amarantbg.com', phone: '+359 877160003', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.1495099, lng: 23.71569 },
        /** Мизия */
        { name: 'гр. Мизия', address: 'ул. Георги Димитров - 39', email: 'violeta_qkimova@mail.bg', phone: '+359 899765700', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.6821161, lng: 23.8585106 },
        /** Нова Загора */
        { name: 'гр. Нова Загора', address: 'ул. Патриарх Евтимий 89', email: 'nova_zagora@amarantbg.com', phone: '+359 879581611', phone2: '045762197', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.4980697, lng: 26.0003296 },
        /** Омуртаг */
        { name: 'гр. Омуртаг', address: 'ул. Славейков - 5', email: 'plamen.mp@abv.bg', phone: '+359 888775690', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.1082095, lng: 26.4178616 },
        /** Оряхово */
        { name: 'гр. Оряхово', address: 'ул. 9-ти ноември - 2', email: 'oriahovo@amarantbg.com', phone: '+359 895663539', phone2: '0894052373', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.7322358, lng: 23.9607337 },
        /** Пaвликени */
        { name: 'гр. Пaвликени', address: 'пл.Стефан Караджа - 16', email: 'office_pavlikeni@amarantbg.com', phone: '+359 885335353', phone2: '0883569871', phone3: '061094404', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.2363925, lng: 25.3061416 },
        /** Пазарджик */
        { name: 'гр. Пазарджик Запад', address: 'бул. Христо Ботев - 55', email: 'zapad@amarantbg.com', phone: '+359 999987908', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.1945429, lng: 24.320109 },
        /** Перник */
        { name: 'гр. Перник - Зори', address: 'кв.Изток, КООП Пазар 7 (зад магазин Билла)', email: '', phone: '+359 882351074', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.6091844, lng: 23.0996211 },
        /** Перущица */
        { name: 'гр. Перущица', address: 'ул. Свещеник Петър Велчев 12,ет.1', email: 'perushtitsa@amarantbg.com', phone: '+359 893513568', phone2: '0877107039', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.0582693, lng: 24.5482974 },
        /** Пещера */
        { name: 'гр. Пещера', address: 'ул. Георги Кьосеиванов - 6А', email: 'peshtera@amarantbg.com', phone: '+359 898820355', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.6352666, lng: 23.3524678 },
        { name: 'гр. Пещера Изгрев', address: 'ул. Симон Налбант - 70', email: '', phone: '+359 895312558', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.0375551, lng: 24.3144745 },
        { name: 'гр. Пещера', address: 'ул. Петър Раков - 17', email: 'office_peshtera@amarantbg.com', phone: '+359 887239553', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.0303097, lng: 24.2985147 },
        /** Плевен */
        { name: 'гр. Плевен', address: 'бул. Дойран - 1 (бензиностанция Шел)', email: 'pleven@amarantbg.com', phone: '+359 895668562 ', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.4186691, lng: 24.6090484 },
        { name: 'гр. Плевен - 2', address: 'ул. Димитър Константинов - 29', email: 'office_pleven@amarantbg.com', phone: '+359 887512822', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.4070416, lng: 24.6213831 },
        { name: 'гр. Плевен - 3', address: 'ул. Данаил Попов - 7', email: 'pleven3@amarantbg.com', phone: '+359 877553949', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.4166228, lng: 24.6159959 },
        { name: 'с. Опанец', address: 'ул. Петър Габровки - 1а, обл. Плевен', email: 'opanets@amarantbg.com', phone: '+359 87639826', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.448139, lng: 24.573852 },
        { name: 'с. Опанец - 2', address: 'ул. Христо Смирненски - 1, обл. Плевен', email: 'opanets@amarantbg.com', phone: '+359 888098944', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.6847776, lng: 23.3309087 },
        /** Раковски */
        { name: 'Раковски', address: 'ул. Москва 19', email: 'rakovski-amarant@abv.bg', phone: '+359 888478298', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.2860659, lng: 24.9710319 },
        /** Разград */
        { name: 'гр. Разград', address: 'ул. Юмрукчал 7', email: 'kristina.kaneva@amarantbg.com', phone: '+359 898655723', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.5164568, lng: 26.5177322 },
        { name: 'гр. Разград - 2', address: 'ул. Велико Търново - 15, офис Ж', email: 'razgrad_2@amarantbg.com', phone: '+359 8887049349', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.5288862, lng: 26.5135182 },
        /** Роман */
        { name: 'гр. Роман', address: 'ул. Търговска - 5', email: 'vilimarinova67@abv.bg', phone: '+359 896343499', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.1485443, lng: 23.9254917 },
        /** Самоков */
        { name: 'гр. Самоков', address: 'бул. Искър 103', email: 'samokov@amarantbg.com', phone: '+359 877831775', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.3343212, lng: 23.5561041 },
        /** Сандански */
        { name: 'гр. Сандански', address: 'ул. Скопие - 26', email: 'sandanski@amarantbg.com', phone: '+359 886315150', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 41.5617744, lng: 23.2787954 },
        { name: 'Сандански Изгрев', address: 'кв. Изгрев, ул. Родопа 2', email: 'sandanski@amarantbg.com', phone: '+359 879 613456', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 41.56117, lng: 23.27768 },
        /** Свищов */
        { name: 'гр. Свищов', address: 'ул. Димитър Анев - 20', email: 'office_svishtov@amarantbg.com', phone: '+359 888022663 ', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.618396, lng: 25.3452569 },
        /** Сливен */
        { name: 'гр. Сливен', address: 'ул. Подем 21 Б', email: 'sliven@amarantbg.com', phone: '+359 894466266', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.6815529, lng: 26.3231373 },
        /** Смолян */
        { name: 'гр. Смолян', address: 'ул. Христо Христов 2Б', email: 'kubrat@amarantbg.com', phone: '+359 898904392', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 41.5841096, lng: 24.685264 },
        /** Стамболийски */
        { name: 'гр. Стамболийски', address: 'бул. Търговски.', email: 'blagavalkova@amarantbg', phone: '+359 886104499', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.1329631, lng: 24.53998 },
        /** Стара Загора */
        { name: 'гр. Стара Загора Фронт офис', address: 'ул. Христо Ботев - 104', email: 'fo_stzagora@amarantbg.com', phone: '+359 886699799', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.4223646, lng: 25.6319051 },
        { name: 'гр. Стара Загора КАТ', address: 'ул. Промишлена 2', email: 'kubrat@amarantbg.com', phone: '+350888000650', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.4090975, lng: 25.6359381 },
        /** Троян */
        { name: 'гр. Троян', address: 'ул. Васил Левски 57', email: 'troyan@amarantbg.com', phone: '+359 670/92030', phone2: '0893492201', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.8869027, lng: 24.7127072 },
        /** Търговище */
        { name: 'гр. Търговище', address: 'ул. Поп Сава Катрафилов 2', email: 'svetlana@amarantbg.com', phone: '+359 894992942', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.2456854, lng: 26.5688402 },
        { name: 'гр. Търговище - Офис 100 Клон', address: 'бул. Митрополит Андрей - 37', email: 'targovishte@amarantbg.com', phone: '+359 898491422', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.2467798, lng: 26.568466 },
        { name: 'гр. Търговище', address: 'ул. Г.С. Раковски - 99', email: 'ismedismedov@gmail.com', phone: '+359 0894999775', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.243245, lng: 26.5698797 },
        /** Хасково */
        { name: 'гр. Хасково', address: 'ул. Бяло море 21', email: 'asen@amarantbg.com', phone: '+359 887623932', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 41.9297933, lng: 25.543575 },
        /** Хисаря */
        { name: 'гр. Хисаря', address: 'ул. Аугуста 16', email: 'hisarya@amarantbg.com', phone: '+359 886891985', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.5036258, lng: 24.7014426 },
        /** Шумен */
        { name: 'гр. Шумен - 1', address: 'ул. Ген. Драгомиров - 5А', email: 'shumen@amarantbg.com', phone: '+359 996677777 ', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.2757622, lng: 26.910193 },
        { name: 'гр. Шумен - 2', address: 'бул. Симеон Велики 42 (паркинг на супермаркет Ешрефоглу)', email: 'eshrefoglu@amarantbg.com', phone: '+359 897575546 ', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 43.2757622, lng: 26.910193 },
        /** Ямбол */
        { name: 'гр. Ямбол', address: 'ул. Преслав - 2', email: 'office_yambol@amarantbg.com', phone: '+359 888536088', phone2: '+359 896382250', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.4804423, lng: 26.5094397 },
        { name: 'гр. Ямбол', address: 'ул. Българка', email: 'yambol@amarantbg.com', phone: '+359 8894466230', phone2: '0878201436', workdays: 'Понеделник - Петък', workhours: '09:00 - 18:00', mainoffice: false, lat: 42.4820347, lng: 26.4958326 },

      ],
      officesListArr: [],
      mapsApiLoaded: false,
      mapInstance: null,
      mapsapi: null,
      searchPlace: null,
      searchName: null,
      searchInput: null,
      zoom: 10
    }
  }
  apiLoaded = (map, maps) => {

    this.setState({
      mapsApiLoaded: true,
      mapInstance: map,
      mapsapi: maps,
    });
  }
  markerClick = (index) => {
    if (document.getElementById(index).style.visibility == "hidden") { document.getElementById(index).style.visibility = "visible" }
    else { document.getElementById(index).style.visibility = "hidden" }
  }

  componentDidMount() {
    this.setState({
      officesListArr: this.state.officesList
    })
  }

  render() {

    const officesListHTML =
      this.state.searchInput ?
        this.state.officesListArr.filter(officesListArr => officesListArr.name.includes(this.state.searchInput)).map((officesListArr, index) =>
          <div key={'office-' + index} id={'office-' + index} className='col'>
            <div className={officesListArr.mainoffice ? 'office office-main' : 'office'} >
              <div className='info-address'>
                <h4>{officesListArr.name}</h4>
                <p className='address'>{officesListArr.address}</p>
                {(officesListArr.email || officesListArr.email2) ?
                  <p className='email'>
                    {(officesListArr.email) ? <span>{officesListArr.email}</span> : null}
                    {(officesListArr.email2) ? <span>{officesListArr.email2}</span> : null}
                  </p> : null}
                <p className='phone'>
                  {(officesListArr.phone) ? <span>{officesListArr.phone}</span> : null}
                  {(officesListArr.phone2) ? <span>{officesListArr.phone2}</span> : null}
                  {(officesListArr.phone3) ? <span>{officesListArr.phone3}</span> : null}
                </p>
              </div>
              <div className='info-worktime'>
                <h5>Работно време</h5>
                <p className='workdays'>{officesListArr.workdays}</p>
                <p className='workhours'>{officesListArr.workhours}</p>
              </div>
            </div>
          </div>) :
        this.state.officesListArr.map((officesListArr, index) =>
          <div key={'office-' + index} id={'office-' + index} className='col'>
            <div className={officesListArr.mainoffice ? 'office office-main' : 'office'} >
              <div className='info-address'>
                <h4>{officesListArr.name}</h4>
                <p className='address'>{officesListArr.address}</p>
                {(officesListArr.email || officesListArr.email2) ?
                  <p className='email'>
                    {(officesListArr.email) ? <span>{officesListArr.email}</span> : null}
                    {(officesListArr.email2) ? <span>{officesListArr.email2}</span> : null}
                  </p> : null}
                <p className='phone'>
                  {(officesListArr.phone) ? <span>{officesListArr.phone}</span> : null}
                  {(officesListArr.phone2) ? <span>{officesListArr.phone2}</span> : null}
                  {(officesListArr.phone3) ? <span>{officesListArr.phone3}</span> : null}
                </p>
              </div>
              <div className='info-worktime'>
                <h5>Работно време</h5>
                <p className='workdays'>{officesListArr.workdays}</p>
                <p className='workhours'>{officesListArr.workhours}</p>
              </div>
            </div>
          </div>)

    const officesListPins = this.state.officesListArr.map((officesListArr, index) =>
      <AddressMarker
        lat={officesListArr.lat}
        lng={officesListArr.lng}
      // text={officesListArr.name}
      />
    )
    const infoBox = this.state.officesListArr.map((officesListArr, index) =>
      <div
        lat={officesListArr.lat + 0.0100000}
        lng={officesListArr.lng + 0.0100000}
        id={index}
        className='info-box'
        style={{ visibility: 'hidden' }}
        onClick={this.markerClick.bind(this, index)}
      >
        <h3>{officesListArr.name}</h3>
        <p>{officesListArr.address}</p>
        <p>{officesListArr.phone}</p>
        <p>{officesListArr.workdays}</p>
        <p>{officesListArr.workhours}</p>
      </div>
    )

    return (
      <div className="offices">

        <div className="section section-main">

          <div className='google-map-wrap'>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: 'AIzaSyALipb3WN32r90_zyf2wk-vNrEod_JdR5Q',
                libraries: ['places', 'drawing'],
              }}
              yesIWantToUseGoogleMapApiInternals
              defaultCenter={this.state.searchPlace ? this.state.searchPlace : this.props.center}
              center={this.state.searchPlace}
              zoom={this.state.zoom}
              onChildClick={this.markerClick.bind(this)}
              onGoogleApiLoaded={({ map, maps }) => {
                this.apiLoaded(map, maps);
              }}
            >
              {officesListPins}
              {infoBox}
            </GoogleMapReact>
          </div>

          <div className='container'>
            <h2>Офисна мрежа</h2>
            <p>Имаме над 230 офиса в страната!</p>
            <div className='search-form'>
              {this.state.mapsApiLoaded &&
                <SearchBox map={this.state.mapInstance} placeholder='Започнете да пишете вашият град' mapsapi={this.state.mapsapi}
                  onPlacesChanged={() => { console.log(this.state.searchPlace) }}
                  onChange={(input) => { this.setState({ searchInput: input }); }}
                  searchPlace={(searchPlace) => { this.setState({ searchPlace: { lat: searchPlace[0].geometry.location.lat(), lng: searchPlace[0].geometry.location.lng() }, zoom: 12, searchName: searchPlace[0].name }); }}
                />}
              {/* <input type='text' placeholder='Започнете да пишете вашият град' className='search-input' /> */}
              <div className='box-bttn'><button className='search-bttn'><img src='/icons/search.svg' alt='търси' /></button></div>
            </div>
          </div>

        </div>

        <div className="section section-offices-list">
          <div className='container'>
            <div className='row'>
              {officesListHTML}
            </div>
          </div>
        </div>

      </div>
    )
  }
}

export default connect()(Offices)