import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

class SearchBox extends Component {
    static propTypes = {
        mapsapi: PropTypes.shape({
            places: PropTypes.shape({
                SearchBox: PropTypes.func,
            }),
            event: PropTypes.shape({
                clearInstanceListeners: PropTypes.func,
            }),
        }).isRequired,
        placeholder: PropTypes.string,
        onPlacesChanged: PropTypes.func,
    };

    static defaultProps = {
        placeholder: 'Search...',
        onPlacesChanged: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            searchPlace: null,
            input: null,
        }
        this.searchInput = React.createRef();
    }

    componentDidMount() {
        const {
            mapsapi: { places },
        } = this.props;
        this.searchBox = new places.SearchBox(this.searchInput.current);
        this.searchBox.addListener('places_changed', this.onPlacesChanged);
    }

    componentWillUnmount() {
        const {
            mapsapi: { event },
        } = this.props;
        event.clearInstanceListeners(this.searchBox);
    }

    onPlacesChanged = () => {
        const { onPlacesChanged } = this.props;
        if (onPlacesChanged) {
            onPlacesChanged(this.searchBox.getPlaces());
            this.setState({ searchPlace: this.searchBox.getPlaces() }, () => this.props.searchPlace(this.state.searchPlace));
        }
    };
    onChange = (e) => {
        this.setState({ input: e.target.value }, () => this.props.onChange(this.state.input))
    }

    render() {
        const { placeholder } = this.props;
        return (
            <input
                ref={this.searchInput}
                placeholder={placeholder}
                className='search-input'
                type="text"
                value={this.state.input}
                onChange={this.onChange.bind(this)}
            />
        );
    }
}

export default SearchBox;