import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withGetScreen } from 'react-getscreen'
import { BenefitVideo1 } from '../SectionBenefits'

class BenefitInfo1 extends Component {
    render() {
        return (
            <div className='benefit-info'>
                <p>Бързата проверка дава възможност бързо и лесно да проверите:</p>
                <ul className='ul'>
                    <li>Неплатени глоби към КАТ</li>
                    <li>Валидност на полица Гражданска Отговорност</li>
                    <li>Има ли Вашият автомобил валидна винетка</li>
                </ul>
                { this.props.isMobile() ? <div><BenefitVideo1/></div> : "" }
            </div>
        )
    }
}

const options = { mobileLimit: 1024, shouldListenOnResize: true  }
export default connect()(withGetScreen(BenefitInfo1, options))
