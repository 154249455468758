import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const AddressMarker = ({ text }) => <div className='gmap-marker'>
    <span className='gmap-pin'><img src='/icons/gmap-pin.svg' alt=''/></span>{text}
</div>;

class GMap extends Component {

    static defaultProps = {
        center: {
            lat: 42.6904114,
            lng: 23.3154911
        },
        zoom: 17
    };
    render() {
        return (
            <div className='gmap'>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyALipb3WN32r90_zyf2wk-vNrEod_JdR5Q'}}
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                >
                    <AddressMarker
                        lat={42.6906042}
                        lng={23.314783}
                        text="ул. Хан Аспарух - 5"
                    />
                </GoogleMapReact>
            </div>
        )
    }
}
export default GMap;